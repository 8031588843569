import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'aws-amplify'
import Papa from 'papaparse'

import TranslationCard from './Card'
import TranslationModal from './Modal'
import Loader from '../Shared/Loader'

import {
  getMissingTranslations,
  getTranslationsRequested,
  getTranslationsLoading
} from '../../reducers/selectors'
import { sendRequestMissingTranslations } from '../../slices/translations'

const Translations = () => {
  const [showCsvModal, setShowCsvModal] = useState(false)
  const [csvContent, setCsvContent] = useState({})
  const dispatch = useDispatch()
  const translations = getMissingTranslations()
  const requested = getTranslationsRequested()
  const loading = getTranslationsLoading()

  if (!requested) {
    dispatch(sendRequestMissingTranslations())
  }

  async function showCsvContent(fileName, url) {
    Papa.parse(url, {
      download: true,
      downloadRequestHeaders: {
        'Access-Control-Allow-Origin': '*'
      },
      complete: function (results) {
        setCsvContent({
          fileName,
          content: results.data.filter(item => item[0].length > 0)
        })
        setShowCsvModal(true)
      }
    })
  }

  function onClickClose(e) {
    e.preventDefault()
    setShowCsvModal(false)
    setCsvContent({})
  }

  return (
    <div>
      <div>
        <h1 className='font-black text-2xl'>{I18n.get('Translations')}</h1>
        <p className='py-2'>
          {I18n.get('Manage missing translation strings on frontend projects.')}
        </p>
      </div>
      {loading && (
        <Loader fullpage={false} text='Fetching missing translations' />
      )}
      {!loading && (
        <div className='grid grid-cols-2 max-w-3xl pt-8'>
          {Object.keys(translations).map(key => {
            return (
              <TranslationCard
                key={key}
                name={key}
                items={translations[key]}
                showCsvContent={showCsvContent}
              />
            )
          })}
        </div>
      )}
      {showCsvModal && csvContent?.fileName?.length > 0 && (
        <TranslationModal
          fileName={csvContent?.fileName}
          content={csvContent?.content}
          onClickClose={onClickClose}
        />
      )}
    </div>
  )
}

export default Translations
