import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { setErrors } from '../../actions/util'
import {
  getEC2Instances,
  getEC2Instance,
  startEC2Instance,
  stopEC2Instance
} from '../../api/ec2'

export const sendRequestEc2Instances = createAsyncThunk(
  'sendRequestEc2Instances',
  async (params, { dispatch }) => {
    const { instances, error } = await getEC2Instances(params)
    if (error) dispatch(setErrors(error))
    return { instances, error }
  }
)

export const sendRequestEc2Instance = createAsyncThunk(
  'sendRequestEc2Instance',
  async (params, { dispatch }) => {
    const { instance, error } = await getEC2Instance(params)
    if (error) dispatch(setErrors(error))
    return { instance, error }
  }
)

export const sendStartEc2Instance = createAsyncThunk(
  'sendStartEc2Instance',
  async (params, { dispatch }) => {
    const { instance, error } = await startEC2Instance(params)
    if (error) dispatch(setErrors(error))
    return { instance, error }
  }
)

export const sendStopEc2Instance = createAsyncThunk(
  'sendStopEc2Instance',
  async (params, { dispatch }) => {
    const { instance, error } = await stopEC2Instance(params)
    if (error) dispatch(setErrors(error))
    return { instance, error }
  }
)

const ec2Slice = createSlice({
  name: 'ec2Reducer',
  initialState: {
    error: null,
    instances: [],
    instanceId: null,
    isLoading: false,
    instancesRequested: false
  },
  extraReducers: builder => {
    builder
      .addCase(sendRequestEc2Instances.pending, (state, action) => {
        state.isLoading = true
        state.error = null
        state.instancesRequested = true
      })
      .addCase(sendRequestEc2Instances.fulfilled, (state, action) => {
        const { error, instances } = action.payload
        if (!error) state.instances = instances
        state.error = error ?? null
        state.isLoading = false
      })
      .addCase(sendRequestEc2Instance.pending, (state, action) => {
        state.isLoading = true
        state.error = null
        state.instanceId = action.meta.arg.InstanceId
      })
      .addCase(sendRequestEc2Instance.fulfilled, (state, action) => {
        const { instance, error } = action.payload

        if (!error) {
          let instances = [...state.instances]
          const index = instances.findIndex(
            ({ InstanceId }) => InstanceId === instance.InstanceId
          )

          if (index > -1) {
            instances[index] = instance
          }

          state.instances = instances
        }

        state.instanceId = null
        state.error = error ?? null
        state.isLoading = false
      })
      .addCase(sendStartEc2Instance.pending, (state, action) => {
        state.isLoading = true
        state.error = null
        state.instanceId = action.meta.arg.InstanceId
      })
      .addCase(sendStartEc2Instance.fulfilled, (state, action) => {
        const { instance, error } = action.payload

        if (!error) {
          let instances = [...state.instances]
          const index = instances.findIndex(
            ({ InstanceId }) => InstanceId === instance.InstanceId
          )

          if (index > -1) {
            instances[index].State = instance.State
          }

          state.instances = instances
        }

        state.instanceId = null
        state.error = error ?? null
        state.isLoading = false
      })
      .addCase(sendStopEc2Instance.pending, (state, action) => {
        state.isLoading = true
        state.error = null
        state.instanceId = action.meta.arg.InstanceId
      })
      .addCase(sendStopEc2Instance.fulfilled, (state, action) => {
        const { instance, error } = action.payload

        if (!error) {
          let instances = [...state.instances]
          const index = instances.findIndex(
            ({ InstanceId }) => InstanceId === instance.InstanceId
          )

          if (index > -1) {
            instances[index].State = instance.State
          }

          state.instances = instances
        }

        state.error = error ?? null
        state.isLoading = false
      })
  }
})

export default ec2Slice.reducer
