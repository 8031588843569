import { API, graphqlOperation } from 'aws-amplify'

import { API_NAME, GRAPHQL_ROUTE } from '../config'
import { getIdToken } from '../utils'

export async function initiateAuth(args) {
  try {
    const mutation = /* GraphQL */ `
      mutation initiateAuth(
        $username: String!
        $password: String!
        $environment: String!
      ) {
        initiateAuth(
          username: $username
          password: $password
          environment: $environment
        ) {
          AuthenticationResult {
            RefreshToken
            ExpiresIn
            IdToken
          }
        }
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(mutation, args)
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return { auth: response.data.initiateAuth, error: null }
  } catch (err) {
    return { auth: null, error: err.message }
  }
}

export async function refreshInitiateAuth(args) {
  try {
    const mutation = /* GraphQL */ `
      mutation refreshInitiateAuth(
        $refreshToken: String!
        $environment: String!
      ) {
        refreshInitiateAuth(
          refreshToken: $refreshToken
          environment: $environment
        ) {
          AuthenticationResult {
            RefreshToken
            ExpiresIn
            IdToken
          }
        }
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(mutation, args)
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return { auth: response.data.refreshInitiateAuth, error: null }
  } catch (err) {
    return { auth: null, error: err.message }
  }
}

export async function getMissingtMigratedUsers(args) {
  try {
    const query = /* GraphQL */ `
      query getMissingtMigratedUsers {
        getMissingtMigratedUsers {
          Dev {
            Username
            Email
          }
          Prod {
            Username
            Email
          }
        }
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(query, args)
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return { users: response.data.getMissingtMigratedUsers, error: null }
  } catch (err) {
    return { users: null, error: err.message }
  }
}
