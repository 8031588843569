import { I18n } from 'aws-amplify'

export default function Table({ users, env }) {
  return (
    <table className='table-auto w-full text-sm font-extrabold'>
      <thead>
        <tr className='text-left border-b border-slate-100 text-lg'>
          <th className='p-2'>
            {env} {I18n.get('users')} ({users.length})
          </th>
          <th className='p-2'></th>
        </tr>
        <tr className='text-left border-b border-slate-100'>
          <th className='p-2'>Username</th>
          <th className='p-2'>Email</th>
        </tr>
      </thead>
      <tbody>
        {users.map(({ Username, Email }) => {
          return (
            <tr
              key={Username}
              className='border-b border-slate-100 font-normal text-base'
            >
              <td className='p-2'>{Username}</td>
              <td className='p-2'>{Email}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
