import { I18n } from 'aws-amplify'
import {
  HomeIcon,
  LanguageIcon,
  CpuChipIcon,
  CodeBracketIcon,
  CurrencyDollarIcon,
  BanknotesIcon,
  UsersIcon,
  InformationCircleIcon,
  FlagIcon
} from '@heroicons/react/24/outline'

const iconClass = 'h-5 w-5 inline mr-3 mb-1'

let navItems = [
  {
    id: 'dashboard',
    path: '/',
    name: I18n.get('Dashboard'),
    icon: <HomeIcon className={iconClass} />
  },
  {
    id: 'graphql',
    path: '/graphql',
    name: I18n.get('GraphQL'),
    icon: <CodeBracketIcon className={iconClass} />,
    children: [
      {
        id: 'graphql-dev',
        path: '/graphql/dev',
        name: I18n.get('Development')
      },
      {
        id: 'graphql-prod',
        path: '/graphql/prod',
        name: I18n.get('Production')
      }
    ]
  },
  {
    id: 'costs',
    path: '/costs',
    name: I18n.get('Costs'),
    icon: <BanknotesIcon className={iconClass} />
  },
  {
    id: 'timestream-costs',
    path: '/timestream-costs',
    name: I18n.get('Timestream costs'),
    icon: <CurrencyDollarIcon className={iconClass} />
  },
  {
    id: 'ec2',
    path: '/ec2',
    name: I18n.get('EC2'),
    icon: <CpuChipIcon className={iconClass} />
  },
  {
    id: 'translations',
    path: '/translations',
    name: I18n.get('Translations'),
    icon: <LanguageIcon className={iconClass} />
  },
  {
    id: 'migration-users',
    path: '/migration-users',
    name: I18n.get('Migration users'),
    icon: <UsersIcon className={iconClass} />
  },
  // {
  //   id: 'sprint-reports',
  //   path: '/sprint-reports',
  //   name: I18n.get('Sprint reports'),
  //   icon: <PresentationChartLineIcon className={iconClass} />
  // },
  {
    id: 'fyi',
    path: '/fyi',
    name: I18n.get('FYI'),
    icon: <InformationCircleIcon className={iconClass} />
  },
  {
    id: 'feature-flags',
    path: '/feature-flags',
    name: I18n.get('Feature Flags'),
    icon: <FlagIcon className={iconClass} />
  }
]

export const getAllNavItems = () => {
  return navItems
}
