import { Fragment, useState } from 'react'
import { I18n, Hub } from 'aws-amplify'

import PasswordReset from './PasswordReset'
import VerifyAttribute from './VerifyAttribute'
import { useEffect } from 'react'
import { AuthState } from '@aws-amplify/ui-components'
import SignIn from './SignIn'
import useAuthChannel from '../../hooks/useAuthChannel'

function Auth() {
  const [hubData, setHubData] = useState(null)

  const [authChannel, channelId] = useAuthChannel()

  useEffect(() => {
    return () => Hub.remove(channelId)
  }, [])

  useEffect(() => {
    switch (authChannel.message) {
      case AuthState.ResetPassword:
        setHubData({ component: 'passwordReset', ...authChannel.data })
        break
      case AuthState.VerifyingAttributes:
        let hubData = {}
        if (
          authChannel?.data?.unverified?.email ||
          authChannel?.data?.type === 'email'
        ) {
          hubData = {
            attribute: 'email',
            title: I18n.get('Email'),
            attributeValue: authChannel?.data?.unverified?.email || null
          }
        }
        setHubData({ component: 'verifyAttribute', ...hubData })
        break
      case 'success':
        setHubData({ component: 'signIn', ...authChannel.data })
        break
      default:
        setHubData({ component: 'signIn', ...authChannel.data })
        break
    }
  }, [authChannel])

  return (
    <Fragment>
      <div className='flex flex-col justify-center items-center w-full gap-10'>
        {hubData?.component === 'verifyAttribute' && (
          <VerifyAttribute
            attribute={hubData.attribute}
            title={hubData.title}
            attributeValue={hubData.attributeValue}
          />
        )}
        {hubData?.component === 'passwordReset' &&
          hubData?.type === 'NewPasswordRequired' && (
            <PasswordReset
              type='NewPasswordRequired'
              needOldPassword={false}
              hubData={hubData}
            />
          )}

        {hubData?.component === 'passwordReset' &&
          (hubData?.type === 'UserForgotPassword' ||
            hubData.type === 'PasswordResetRequiredException') && (
            <PasswordReset
              type='UserForgotPassword'
              needOldPassword={false}
              needCode={true}
              hubData={hubData}
            />
          )}
        {hubData?.component === 'signIn' && <SignIn hubData={hubData} />}
      </div>
    </Fragment>
  )
}

export default Auth
