import { requestGenerateZenhubReport } from '../zenhub_reports'

import { createSlice } from '@reduxjs/toolkit'

const utilSlice = createSlice({
  name: 'utilReducer',
  initialState: {
    success: null,
    error: null
  },
  reducers: {
    cleanBanner: (state, action) => {
      state.success = null
      state.error = null
    }
  },
  extraReducers: builder => {
    builder.addCase(requestGenerateZenhubReport.fulfilled, (state, action) => {
      state.success = action?.payload?.report
      state.error = action?.payload?.error
    })
  }
})

const { actions, reducer } = utilSlice

export const { cleanBanner } = actions

export default reducer
