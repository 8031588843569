import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'

import UpsertModal from './UpsertModal'

import {
  sendSyncFeatureFlags,
  sendFetchFeatureFlags
} from '../../slices/feature_flags'
import {
  getFeatureFlagsSyncing,
  getFeatureFlags,
  getFeatureFlagsRequested
} from '../../reducers/selectors'

const FeatureFlags = () => {
  const [showUpsertModal, setShowUpsertModal] = useState(false)
  const [selectedFeatureFlag, setSelectedFeatueFlag] = useState(null)
  const dispatch = useDispatch()
  const isSyncing = getFeatureFlagsSyncing()
  const requested = getFeatureFlagsRequested()
  const featureFlags = getFeatureFlags()

  function syncFeatreuFlagsDevToProd() {
    dispatch(sendSyncFeatureFlags({ fromEnv: 'dev', toEnv: 'prod' }))
  }

  function syncFeatureFlagsTextProdToDev() {
    dispatch(sendSyncFeatureFlags({ fromEnv: 'prod', toEnv: 'dev' }))
  }

  useEffect(() => {
    if (!requested) {
      dispatch(sendFetchFeatureFlags())
    }
  }, [requested, dispatch])

  function onShowModal() {
    setShowUpsertModal(true)
  }

  function onHideModal() {
    setShowUpsertModal(false)
    setSelectedFeatueFlag(null)
  }

  return (
    <div>
      {showUpsertModal && (
        <UpsertModal
          closeModal={onHideModal}
          featureFlag={selectedFeatureFlag}
        />
      )}
      <div>
        <h1 className='font-black text-2xl'>{I18n.get('Feature flags')}</h1>
        <p className='py-2'>{I18n.get('Manage feature flags for Coretex.')}</p>
      </div>
      <div className='mt-4'>
        <h2 className='font-black text-xl mb-1'>
          {I18n.get('Sync feature flags')} {isSyncing && '(syncing...)'}
        </h2>
        <p className='py-2'>{I18n.get('Sync feature flags from dev/prod.')}</p>
        <div className='flex justify-start gap-2'>
          <button
            className='text-sm rounded px-4 py-2 bg-blue-500 text-white hover:bg-blue-700'
            onClick={syncFeatreuFlagsDevToProd}
            disabled={isSyncing}
          >
            Dev to Prod
          </button>
          <button
            className='text-sm rounded px-4 py-2 bg-blue-500 text-white hover:bg-blue-700'
            onClick={syncFeatureFlagsTextProdToDev}
            disabled={isSyncing}
          >
            Prod to Dev
          </button>
        </div>
      </div>
      <div className='flex justify-between items-center'>
        <h2 className='font-black text-xl mb-1 mt-6 mb-4'>
          {I18n.get('Current feature flags')}
        </h2>
        <button
          className='h-10 text-sm rounded px-4 py-2 bg-blue-500 text-white hover:bg-blue-700'
          onClick={onShowModal}
          // disabled={isSyncing}
        >
          Add
        </button>
      </div>
      <table className='table-auto container text-left'>
        <thead className='bg-slate-50 text-slate-500'>
          <tr>
            <th className='p-2 font-semibold'>Name</th>
            <th className='font-semibold'>Type</th>
            <th className='font-semibold'>Options</th>
          </tr>
        </thead>
        <tbody>
          {featureFlags?.map(featureFlag => {
            function onClickEdit() {
              setSelectedFeatueFlag(featureFlag)
              setShowUpsertModal(true)
            }

            const { featureFlagId, name, type } = featureFlag

            return (
              <tr className='border-b-2 border-slate-50' key={featureFlagId}>
                <td className='p-2'>{name}</td>
                <td>{type}</td>
                <td>
                  <button
                    className='text-sm rounded px-4 py-1 bg-blue-500 text-white hover:bg-blue-700'
                    onClick={onClickEdit}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default FeatureFlags
