import { Fragment, useEffect, useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { I18n } from 'aws-amplify'

const GraphQLContainer = () => {
  const location = useLocation()
  const [selectedEnv, setSelectedEnv] = useState('')

  const tileClass =
    'bg-slate-100/50 hover:bg-slate-600 rounded p-2 h-24 hover:text-white mr-2'

  useEffect(() => {
    setSelectedEnv(location?.pathname?.split('/')[2])
  }, [location?.pathname])

  const getHeadingText = () => {
    if (!selectedEnv) return I18n.get('GraphQL')

    return selectedEnv === 'dev'
      ? I18n.get('GraphQL: Development')
      : I18n.get('GraphQL: Production')
  }

  return (
    <Fragment>
      <div className='flex justify-between items-center'>
        <div>
          <h1 className='font-black text-2xl'>{getHeadingText()}</h1>
          <p className='py-2'>
            {I18n.get(
              'Test GraphQL queries and mutations across different services.'
            )}
          </p>
        </div>

        <Link
          to='/'
          className='text-white text-sm bg-slate-600 hover:bg-slate-100 rounded p-2 hover:text-slate-600 h-9'
        >
          {I18n.get('Return to dashboard')}
        </Link>
      </div>
      {!selectedEnv && (
        <Fragment>
          <div className='grid grid-cols-2 max-w-3xl pt-8'>
            <Link className={tileClass} to='/graphql/dev'>
              <p className='text-center pt-6'>{I18n.get('Development')}</p>
            </Link>
            <Link className={tileClass} to='/graphql/prod'>
              <p className='text-center pt-6'>{I18n.get('Production')}</p>
            </Link>
          </div>
        </Fragment>
      )}
      <Outlet />
    </Fragment>
  )
}

export default GraphQLContainer
