import { API, graphqlOperation } from 'aws-amplify'

import { API_NAME, GRAPHQL_ROUTE } from '../config'
import { getIdToken } from '../utils'

export async function getMissingTranslations() {
  try {
    const query = /* GraphQL */ `
      query getMissingTranslations {
        getMissingTranslations {
          service
          file
          url
        }
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(query, {})
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return {
      translations: response.data.getMissingTranslations,
      error: null
    }
  } catch (err) {
    return { translations: null, error: err.message }
  }
}
