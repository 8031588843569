import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import createSagaMiddleware, { END } from 'redux-saga'
import thunkMiddleware from 'redux-thunk'

import rootReducer from './reducers'

let loggerMiddleware = null
if (process.env.NODE_ENV === 'development') {
  loggerMiddleware = require('redux-logger').createLogger()
}

const sagaMiddleware = createSagaMiddleware()

let store = null

const getStore = () => {
  const middleware = [thunkMiddleware, sagaMiddleware, loggerMiddleware].filter(
    Boolean
  )

  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
      : compose

  const enhancer = composeEnhancers(
    applyMiddleware(...middleware)
    // other store enhancers if any
  )

  store = createStore(
    combineReducers({
      rootReducer
    }),
    enhancer
    //applyMiddleware(...middleware)
  )

  store.close = () => store.dispatch(END)

  return store
}

export default getStore
