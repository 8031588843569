import { API, graphqlOperation } from 'aws-amplify'

import { API_NAME, GRAPHQL_ROUTE } from '../config'
import { getIdToken } from '../utils'

export async function getTimestreamCostsByOrganizationId(params) {
  try {
    const query = /* GraphQL */ `
      query getTimestreamCostsByOrganizationId(
        $organizationId: String!
        $isDev: Boolean!
      ) {
        getTimestreamCostsByOrganizationId(
          organizationId: $organizationId
          isDev: $isDev
        ) {
          organizationId
          costs {
            date
            metered
            scanned
            expenses
          }
        }
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(query, params)
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return {
      timestreamCosts: response.data.getTimestreamCostsByOrganizationId,
      error: null
    }
  } catch (err) {
    return { timestreamCosts: null, error: err.message }
  }
}
