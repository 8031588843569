import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  getZenhubReport,
  generateZenhubReport,
  getZenhubReports,
  getZenhubSprints
} from '../../api/zenhub_reports'

const sortReports = reports => {
  return reports?.sort((a, b) => {
    const aDateString = a.sprintStartDate ?? a.sprintName.split('-')[1].trim()
    const aDate = new Date(aDateString)
    const bDateString = b.sprintStartDate ?? b.sprintName.split('-')[1].trim()
    const bDate = new Date(bDateString)
    return aDate.getTime() > bDate.getTime() ? -1 : 1
  })
}

const getUpdatedReports = (report, oldReports) => {
  const reports = [...oldReports]
  const index = reports.findIndex(({ sprintId }) => {
    return report.sprintId === sprintId
  })

  const { createdAt, updatedAt, ...rest } = report
  const newItem = {
    createdAt: createdAt.toString(),
    updatedAt: updatedAt.toString(),
    ...rest
  }

  if (index > -1) reports[index] = newItem
  if (index === -1) reports.push(newItem)
  return reports
}

export const requestZenhubReports = createAsyncThunk(
  'requestZenhubReports',
  async () => {
    const { reports, error } = await getZenhubReports()

    return {
      reports,
      error: error ?? null
    }
  }
)

export const requestZenhubSprints = createAsyncThunk(
  'requestZenhubSprints',
  async () => {
    const { sprints, error } = await getZenhubSprints()

    return {
      sprints,
      error: error ?? null
    }
  }
)

export const requestGenerateZenhubReport = createAsyncThunk(
  'requestGenerateZenhubReport',
  async params => {
    const response = await generateZenhubReport(params)

    return {
      report: response?.error ? null : response?.report,
      error: response?.error ?? null
    }
  }
)

export const requestZenhubReport = createAsyncThunk(
  'requestZenhubReport',
  async params => {
    const { report, error } = await getZenhubReport(params)
    if (!error) report['sprintId'] = params.sprintId

    return {
      report,
      error: error ?? null
    }
  }
)

const zenhubReportSlice = createSlice({
  name: 'zenhubReportReducer',
  initialState: {
    isRequestingReport: false,
    isLoadingReportData: false,
    reportData: {},
    reports: [],
    sprints: [],
    error: null
  },
  reducers: {
    cleanZenhubReport: (state, action) => {
      state.reportData = {}
      state.reportType = ''
      state.error = null
    },
    setZenhubReportType: (state, action) => {
      state.reportType = action.payload
    },
    setNewZenhubReportGenerated: (state, action) => {
      state.reports = getUpdatedReports(action.payload, state.reports)
    }
  },
  extraReducers: builder => {
    builder
      .addCase(requestZenhubReport.pending, (state, action) => {
        state.isLoadingReportData = true
        state.reportData = {}
        state.error = null
      })
      .addCase(requestZenhubReport.fulfilled, (state, action) => {
        const { report = {}, error = null } = action?.payload
        state.isLoadingReportData = false
        state.reportData = report
        state.error = error
      })
      .addCase(requestGenerateZenhubReport.pending, (state, action) => {
        state.isRequestingReport = action?.payload?.sprintId
        state.error = null
      })
      .addCase(requestZenhubReports.pending, (state, action) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(requestZenhubReports.fulfilled, (state, action) => {
        const { reports, error } = action?.payload
        if (reports?.length > 0 && !error) {
          const sortedReports = sortReports([...reports])
          state.reports = sortedReports
        }
        state.isLoading = false
        state.error = action?.payload?.error
      })
      .addCase(requestZenhubSprints.pending, (state, action) => {
        state.isLoading = true
        state.sprints = []
        state.error = null
      })
      .addCase(requestZenhubSprints.fulfilled, (state, action) => {
        const { sprints = [], error = null } = action?.payload
        state.isLoading = false
        state.sprints = error ? [] : sprints
        state.error = error
      })
  }
})

const { actions, reducer } = zenhubReportSlice

export const {
  cleanZenhubReport,
  setZenhubReportType,
  setNewZenhubReportGenerated
} = actions

export default reducer
