import { API, graphqlOperation } from 'aws-amplify'

import { API_NAME, GRAPHQL_ROUTE } from '../config'
import { getIdToken } from '../utils'

export async function getAllGraphQLApis() {
  try {
    const query = /* GraphQL */ `
      query getAllGraphQLApis {
        getAllGraphQLApis {
          ApiId
          Name
          DomainName
          CorsConfiguration {
            AllowOrigins
          }
          ApiMapping {
            ApiMappingKey
          }
          Routes {
            AuthorizationType
            RouteKey
          }
          Environment
        }
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(query, {})
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    const apis =
      response?.data?.getAllGraphQLApis?.filter(({ Routes }) => {
        return Routes?.some(({ RouteKey }) => {
          return RouteKey.includes('graphql')
        })
      }) ?? []

    return { apis, error: null }
  } catch (err) {
    return { apis: null, error: err.message }
  }
}
