import { createGraphiQLFetcher } from '@graphiql/toolkit'
import { GraphiQL } from 'graphiql'

import 'graphiql/graphiql.css'

const ShowGraphiQL = ({ selectedService, idToken }) => {
  const fetcher = createGraphiQLFetcher({
    url: selectedService?.endpoint,
    headers: { Authorization: idToken }
  })

  return (
    <div className='h-5/6 w-full'>
      <GraphiQL fetcher={fetcher} />
    </div>
  )
}

export default ShowGraphiQL
