const baseButtonClass =
  'whitespace-nowrap self-center h-10 disabled:opacity-50 py-1 px-2 rounded disabled:cursor-not-allowed w-32 mr-1'
export const buttonBlue = `${baseButtonClass} text-sm text-white bg-blue-400 enabled:hover:bg-blue-100 enabled:hover:text-black text-center`

export const getDateFromTs = timestamp => {
  const date = new Date(parseInt(timestamp))
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
}

export const columnHeaders = {
  sprintName: 'Sprint',
  createdAt: 'Report created',
  updatedAt: 'Last updated',
  sprintSummaryUrl: 'Sprint summary',
  sprintBreakdownUrl: 'Sprint breakdown',
  generateReport: 'Generate reports'
}

const pipelineIds = {
  in_progress: {
    id: 'Z2lkOi8vcmFwdG9yL1BpcGVsaW5lLzI0OTAwNjM',
    name: 'In Progress'
  },
  review_qa_dev: {
    id: 'Z2lkOi8vcmFwdG9yL1BpcGVsaW5lLzI0OTAwNjQ',
    name: 'Review/QA Dev'
  },
  done_dev: {
    id: 'Z2lkOi8vcmFwdG9yL1BpcGVsaW5lLzI0OTAwNjU',
    name: 'Done Dev'
  },
  review_qa_prod: {
    id: 'Z2lkOi8vcmFwdG9yL1BpcGVsaW5lLzI0OTEyNzk',
    name: 'Review/QA Prod'
  },
  done_prod: {
    id: 'Z2lkOi8vcmFwdG9yL1BpcGVsaW5lLzI0OTEyODA',
    name: 'Done Prod'
  },
  closed: {
    id: 'closed',
    name: 'Closed'
  }
}

export const pipelineIdValues = Object.keys(pipelineIds)
  .map(key => pipelineIds[key].id)
  .filter(id => id !== 'closed')

export const getSummaryReportHeaders = () => {
  const headers = [
    {
      text: 'User',
      className: 'row-span-2 col-span-2 font-bold px-2 self-end'
    },
    {
      text: 'No. of tickets',
      className: 'row-span-2 font-bold px-2 self-end'
    },
    {
      text: 'Estimated points by pipeline',
      className: 'col-span-7 font-bold self-end'
    }
  ]

  const subHeaders = Object.keys(pipelineIds).map(key => ({
    text: pipelineIds[key].name,
    className: 'px-2 self-end'
  }))

  subHeaders.push({
    text: 'Completed',
    className: 'font-bold px-2 self-end'
  })

  return [...headers, ...subHeaders]
}

const getCellConfig = (text, hasFill, isBold = false, isName = false) => {
  const fillColor = hasFill ? 'bg-slate-100/50' : ''
  const fontWeight = isBold ? 'font-bold' : ''
  const largerCol = isName ? 'col-span-2' : ''
  const className = `self-end py-3 px-2 truncate text-sm ${fillColor} ${fontWeight} ${largerCol}`
  return {
    text,
    className
  }
}

export const getSummaryReportRows = issues_by_user => {
  const sortedUserKeys = Object.keys(issues_by_user).sort()
  return sortedUserKeys.map((key, index) => {
    const { user_name, points, ticketCount } = issues_by_user[key]
    const isEvenRow = Number.isInteger(index / 2)
    const userCell = getCellConfig(user_name, isEvenRow, false, true)
    const ticketCountCell = getCellConfig(ticketCount, isEvenRow)
    let row = [userCell, ticketCountCell]
    Object.keys(pipelineIds).forEach(key => {
      const newCell = getCellConfig(points[key] || 0, isEvenRow)
      row.push(newCell)
    })
    const totalCell = getCellConfig(points.total, isEvenRow, true)
    row.push(totalCell)
    return row
  })
}

export const getDataToSortByEpic = issues => {
  const issuesSortedByEpic = issues.sort((a, b) => {
    if (!b?.epic?.id) return -1
    return a?.epic?.id > b?.epic?.id ? -1 : 1
  })
  const epics = issuesSortedByEpic.reduce((acc, { epic }, index) => {
    const epicId = epic?.id ?? 'other'
    if (!acc.hasOwnProperty(epicId)) acc[epicId] = { start: index, count: 0 }
    acc[epicId]['count']++
    return acc
  }, {})

  return { issuesSortedByEpic, epics }
}
