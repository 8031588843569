import { API, graphqlOperation } from 'aws-amplify'

import { API_NAME, GRAPHQL_ROUTE } from '../config'
import { getIdToken } from '../utils'

/*
 * Queries
 */

export const getFeatureFlags = async () => {
  try {
    const query = /* GraphQL */ `
      query getFeatureFlags {
        getFeatureFlags {
          featureFlagId
          name
          type
        }
      }
    `

    const jwtToken = await getIdToken()
    const operation = graphqlOperation(query)

    const { data } = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (!data?.getFeatureFlags) {
      throw Error('Unable to fetch feature flags')
    }

    return { featureFlags: data?.getFeatureFlags }
  } catch (err) {
    throw err
  }
}

/*
 * Mutations
 */

export async function syncFeatureFlags(params) {
  try {
    const mutation = /* GraphQL */ `
      mutation syncFeatureFlags($fromEnv: String!, $toEnv: String!) {
        syncFeatureFlags(fromEnv: $fromEnv, toEnv: $toEnv)
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(mutation, params)
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return {
      message: response.data.syncFeatureFlags,
      error: null
    }
  } catch (err) {
    return { message: null, error: err.message }
  }
}

export async function createFeatureFlag(params) {
  try {
    const mutation = /* GraphQL */ `
      mutation createFeatureFlag(
        $featureFlagId: String!
        $name: String!
        $type: String!
      ) {
        createFeatureFlag(
          featureFlagId: $featureFlagId
          name: $name
          type: $type
        ) {
          featureFlagId
          name
          type
        }
      }
    `

    const jwtToken = await getIdToken()
    const operation = graphqlOperation(mutation, params)
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return {
      featureFlag: response.data.createFeatureFlag,
      error: null
    }
  } catch (err) {
    return { error: err.message }
  }
}

export async function updateFeatureFlag(params) {
  try {
    const mutation = /* GraphQL */ `
      mutation updateFeatureFlag(
        $featureFlagId: String!
        $name: String!
        $type: String!
      ) {
        updateFeatureFlag(
          featureFlagId: $featureFlagId
          name: $name
          type: $type
        ) {
          featureFlagId
          name
          type
        }
      }
    `

    const jwtToken = await getIdToken()
    const operation = graphqlOperation(mutation, params)
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return {
      featureFlag: response.data.updateFeatureFlag,
      error: null
    }
  } catch (err) {
    return { error: err.message }
  }
}
