import { useEffect, useCallback } from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'

const Modal = ({ headings, toggleView, children }) => {
  const { main, sub } = headings
  const escFunction = useCallback(event => {
    if (event.key === 'Escape') {
      toggleView()
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  return (
    <div className='absolute w-full h-screen top-0 left-0 bg-slate-800/90 z-10 p-10 overflow-y-auto'>
      <div className='m-auto bg-white p-8 w-max max-w-full'>
        <div className='flex justify-between mb-10'>
          <div>
            {main && <h2 className='text-xl font-extrabold'>{main}</h2>}
            {sub && <p className='text-slate-400 font-bold mt-1'>{sub}</p>}
          </div>
          <XMarkIcon className='h-6 w-6 cursor-pointer' onClick={toggleView} />
        </div>
        {children}
      </div>
    </div>
  )
}

export default Modal
