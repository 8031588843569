import { combineReducers } from 'redux'
import { utilReducer } from './util'

// Slices
import apisReducer from '../slices/api_gateway'
import cognitoReducer from '../slices/cognito'
import costExplorerReducer from '../slices/cost_explorer'
import ec2Reducer from '../slices/ec2'
import featureFlagsReducer from '../slices/feature_flags'
import fyiReducer from '../slices/fyi'
import managementSiteReducer from '../slices/management/sites'
import managementOrganizationReducer from '../slices/management/organizations'
import resourceGroupsTaggingReducer from '../slices/resource_groups_tagging'
import timestreamCostsReducer from '../slices/timestream_costs'
import translationsReducer from '../slices/translations'
import zenhubReportsReducer from '../slices/zenhub_reports'
import utilReducerNew from '../slices/utils'

export default combineReducers({
  apisReducer,
  cognitoReducer,
  costExplorerReducer,
  ec2Reducer,
  featureFlagsReducer,
  fyiReducer,
  managementSiteReducer,
  managementOrganizationReducer,
  resourceGroupsTaggingReducer,
  timestreamCostsReducer,
  translationsReducer,
  zenhubReportsReducer,
  utilReducer,
  utilReducerNew
})
