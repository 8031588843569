import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getAllGraphQLApis } from '../../api/api_gateway'
import { setErrors } from '../../actions/util'

export const sendFetchAllGraphQLApis = createAsyncThunk(
  'sendFetchAllGraphQLApis',
  async (params, { dispatch }) => {
    const response = await getAllGraphQLApis()
    if (response?.error) dispatch(setErrors(response?.error))
    return response
  }
)

const apisSlice = createSlice({
  name: 'apisReducer',
  initialState: {
    error: null,
    graphqlApis: [],
    isLoading: false
  },
  extraReducers: builder => {
    builder
      .addCase(sendFetchAllGraphQLApis.pending, state => {
        state.error = null
        state.isLoading = true
      })
      .addCase(sendFetchAllGraphQLApis.fulfilled, (state, action) => {
        const { error, apis } = action.payload
        if (!error) {
          state.graphqlApis = apis
        } else {
          state.error = error
        }
        state.isLoading = false
      })
  }
})

export default apisSlice.reducer
