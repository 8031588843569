import { useState, Fragment, useEffect } from 'react'

const CustomLegend = ({ innerHeight, innerWidth, legendData, width }) => {
  const [settings, data] = legendData[0]
  const { isMobile, setItemsPerCol, itemsPerCol } = settings
  const legendX = !isMobile ? innerWidth + 50 : 0
  const legendY = !isMobile ? 0 : innerHeight + 50
  const [columnCount, setColumnCount] = useState(1)

  useEffect(() => {
    let numOfCols = 1
    if (isMobile) {
      if (300 < width && width < 700) numOfCols = 2
      if (width >= 700) numOfCols = 3
    }

    const itemsPerCol = Math.ceil(data?.length / numOfCols)
    setColumnCount(numOfCols)
    setItemsPerCol(itemsPerCol)
  }, [isMobile, width, data?.length, itemsPerCol, setItemsPerCol])

  const columnWidth = innerWidth / columnCount

  const getItemXPosition = (column, isText = false) => {
    const baseOffset = isText ? 30 : 0
    return columnWidth * column + baseOffset
  }

  const getItemYPosition = (indexInColumn, isText = false) => {
    const baseOffset = isText ? 13 : 0
    return indexInColumn * 22 + baseOffset
  }

  const getLabelText = label => {
    if (label.length > 33) {
      return `${label.slice(0, 30)}...`
    }
    return label
  }

  return (
    <g transform={`translate(${legendX} ${legendY})`}>
      {data.reverse().map(({ label, id, color }, index) => {
        const column = Math.floor(index / itemsPerCol)
        const indexInColumn = index - column * itemsPerCol

        return (
          <Fragment key={id}>
            <rect
              transform={`translate(${getItemXPosition(
                column
              )}, ${getItemYPosition(indexInColumn)})`}
              height={20}
              width={20}
              style={{
                fill: color
              }}
            />
            <text
              transform={`translate(${getItemXPosition(
                column,
                true
              )}, ${getItemYPosition(indexInColumn, true)})`}
              fontSize='12px'
              style={{
                fill: 'rgb(51, 51, 51)',
                fontFamily: 'Inter, sans-serif'
              }}
            >
              {getLabelText(label)}
            </text>
          </Fragment>
        )
      })}
    </g>
  )
}

export default CustomLegend
