import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'

import {
  sendSyncHelpText,
  sendSyncReleaseNotes,
  sendSyncTags
} from '../../slices/fyi'
import { getFyiSyncing } from '../../reducers/selectors'

const Fyi = () => {
  const dispatch = useDispatch()
  const isSyncing = getFyiSyncing()

  function syncHelpTextDevToProd() {
    dispatch(sendSyncHelpText({ fromEnv: 'dev', toEnv: 'prod' }))
  }

  function syncHelpTextProdToDev() {
    dispatch(sendSyncHelpText({ fromEnv: 'prod', toEnv: 'dev' }))
  }

  function syncReleaseNotesDevToProd() {
    dispatch(sendSyncReleaseNotes({ fromEnv: 'dev', toEnv: 'prod' }))
  }

  function syncReleaseNotesProdToDev() {
    dispatch(sendSyncReleaseNotes({ fromEnv: 'prod', toEnv: 'dev' }))
  }

  function syncTagsDevToProd() {
    dispatch(sendSyncTags({ fromEnv: 'dev', toEnv: 'prod' }))
  }

  function syncTagsProdToDev() {
    dispatch(sendSyncTags({ fromEnv: 'prod', toEnv: 'dev' }))
  }

  return (
    <div>
      <div>
        <h1 className='font-black text-2xl'>{I18n.get('FYI')}</h1>
        <p className='py-2'>
          {I18n.get(
            'Sync help text tooltips, release notes and tags from dev/prod.'
          )}
        </p>
      </div>
      <div className='flex mt-2 gap-4 flex-col'>
        <div className='mt-2'>
          <h2 className='font-black text-xl mb-1'>
            {I18n.get('Help Text Sync')} {isSyncing && 'syncing...'}
          </h2>
          <div className='flex justify-start gap-2'>
            <button
              className='text-sm rounded px-4 py-2 bg-blue-500 text-white hover:bg-blue-700'
              onClick={syncHelpTextDevToProd}
              disabled={isSyncing}
            >
              Dev to Prod
            </button>
            <button
              className='text-sm rounded px-4 py-2 bg-blue-500 text-white hover:bg-blue-700'
              onClick={syncHelpTextProdToDev}
              disabled={isSyncing}
            >
              Prod to Dev
            </button>
          </div>
        </div>
        <div className='mt-2'>
          <h2 className='font-black text-xl mb-1'>
            {I18n.get('Release Notes Sync')} {isSyncing && 'syncing...'}
          </h2>
          <div className='flex justify-start gap-2'>
            <button
              className='text-sm rounded px-4 py-2 bg-blue-500 text-white hover:bg-blue-700'
              onClick={syncReleaseNotesDevToProd}
              disabled={isSyncing}
            >
              Dev to Prod
            </button>
            <button
              className='text-sm rounded px-4 py-2 bg-blue-500 text-white hover:bg-blue-700'
              onClick={syncReleaseNotesProdToDev}
              disabled={isSyncing}
            >
              Prod to Dev
            </button>
          </div>
        </div>
        <div className='mt-2'>
          <h2 className='font-black text-xl mb-1'>
            {I18n.get('Tags Sync')} {isSyncing && 'syncing...'}
          </h2>
          <div className='flex justify-start gap-2'>
            <button
              className='text-sm rounded px-4 py-2 bg-blue-500 text-white hover:bg-blue-700'
              onClick={syncTagsDevToProd}
              disabled={isSyncing}
            >
              Dev to Prod
            </button>
            <button
              className='text-sm rounded px-4 py-2 bg-blue-500 text-white hover:bg-blue-700'
              onClick={syncTagsProdToDev}
              disabled={isSyncing}
            >
              Prod to Dev
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Fyi
