import { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'aws-amplify'

import { ArrowPathIcon } from '@heroicons/react/24/solid'

import {
  sendRequestEc2Instance,
  sendStartEc2Instance,
  sendStopEc2Instance
} from '../../slices/ec2'
import { getIsLoadingEC2, getEC2InstanceId } from '../../reducers/selectors'

const baseButtonClass =
  'whitespace-nowrap self-center h-10 disabled:opacity-50 py-1 px-2 rounded disabled:cursor-not-allowed w-32 mr-1'
const buttonBlue = `${baseButtonClass} text-sm text-white bg-blue-400 enabled:hover:bg-blue-100 enabled:hover:text-black text-center`
const buttonTeal = `${baseButtonClass} text-sm bg-teal-400 enabled:hover:bg-teal-600 enabled:hover:text-white`

const Ec2Card = ({
  Architecture,
  InstanceId,
  InstanceType,
  Name,
  PlatformDetails,
  State
}) => {
  const dispatch = useDispatch()
  const isLoading = getIsLoadingEC2()
  const instanceId = getEC2InstanceId()

  function onClickStartInstance(e) {
    e.preventDefault()
    dispatch(sendStartEc2Instance({ InstanceId }))
  }

  function onClickStopInstance(e) {
    e.preventDefault()
    dispatch(sendStopEc2Instance({ InstanceId }))
  }

  function onClickRefresh(e) {
    e.preventDefault()
    dispatch(sendRequestEc2Instance({ InstanceId }))
  }

  return (
    <div className='bg-slate-100/50 rounded p-2 basis-1/4'>
      <div className='mt-2'>
        <p className='justify-self-center text-center m-auto font-extrabold text-lg'>
          {Name}
        </p>
      </div>
      <div className='mt-2'>
        <div className='mb-1'>
          <span className='text-sm font-extrabold'>
            {I18n.get('Architecture')}
          </span>
          <p>{Architecture}</p>
        </div>
        <div className='mb-1'>
          <span className='text-sm font-extrabold'>
            {I18n.get('Instance Id')}
          </span>
          <p>{InstanceId}</p>
        </div>
        <div className='mb-1'>
          <span className='text-sm font-extrabold'>
            {I18n.get('Instance Type')}
          </span>
          <p>{InstanceType}</p>
        </div>
        <div className='mb-1'>
          <span className='text-sm font-extrabold'>
            {I18n.get('Platform Details')}
          </span>
          <p>{PlatformDetails}</p>
        </div>
        <div className='mb-1'>
          <span className='text-sm font-extrabold'>{I18n.get('State')}</span>
          <p>{State}</p>
        </div>
        <div className='flex justify-between mt-4'>
          <button
            onClick={
              State === 'running' ? onClickStopInstance : onClickStartInstance
            }
            className={buttonTeal}
            disabled={
              (instanceId === InstanceId && isLoading) ||
              (State !== 'running' && State !== 'stopped')
            }
          >
            <Fragment>
              <p className='pl-2 inline'>
                {State === 'running'
                  ? I18n.get('Stop instance')
                  : I18n.get('Start instance')}
              </p>
            </Fragment>
          </button>
          <button
            onClick={onClickRefresh}
            className={buttonBlue}
            disabled={instanceId === InstanceId && isLoading}
          >
            <Fragment>
              <ArrowPathIcon className='w-5 mx-auto inline' />
              <p className='pl-2 inline'>{I18n.get('Refresh')}</p>
            </Fragment>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Ec2Card
