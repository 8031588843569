import { I18n } from 'aws-amplify'
import { object, date } from 'yup'
import {
  getMinStartDate,
  getMaxStartDate,
  getMaxEndDate,
  getMinEndDate
} from '../components/Costs/utils'

const dailyRangeLimit = I18n.get('Daily range can not exceed 60 days.')
const pastYearLimit = I18n.get('Select dates within the last year.')
const startAfterEnd = I18n.get('Start date must be before end date.')
const futureDate = I18n.get('Dates can not be in the future.')

const getIsOverMin = (value, start) => {
  const selectedDate = value.valueOf()
  const comparisonDate = new Date(getMinEndDate(start)).valueOf()
  return selectedDate >= comparisonDate
}

const getIsUnderMax = (value, start) => {
  const selectedDate = value.valueOf()
  const comparisonDate = new Date(getMaxEndDate(start)).valueOf()
  return selectedDate <= comparisonDate
}

export const dateRangeSchema = object().shape({
  start: date()
    .min(getMinStartDate(), pastYearLimit)
    .max(getMaxStartDate(), futureDate),
  end: date()
    .max(new Date(), futureDate)
    .test({
      name: 'endMin',
      message: startAfterEnd,
      test: (value, context) => getIsOverMin(value, context.parent.start)
    })
    .test({
      name: 'endMax',
      message: dailyRangeLimit,
      test: (value, context) => getIsUnderMax(value, context.parent.start)
    })
})
