import { API, graphqlOperation } from 'aws-amplify'

import { API_NAME, GRAPHQL_ROUTE } from '../config'
import { getIdToken } from '../utils'

export async function getCostAndUsageWithDateInterval(params) {
  try {
    const query = /* GraphQL */ `
      query getCostAndUsageWithDateInterval(
        $tags: CostsTagsInput
        $groupBy: [CostsGroupByInput]
        $granularity: String
        $metric: String
        $timePeriod: CostExplorerTimePeriodInput
      ) {
        getCostAndUsageWithDateInterval(
          tags: $tags
          groupBy: $groupBy
          granularity: $granularity
          metric: $metric
          timePeriod: $timePeriod
        ) {
          TimePeriod {
            Start
            End
          }
          Groups {
            Services
            Amount
            Currency
          }
        }
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(query, params)
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return {
      costAndUsage: response.data.getCostAndUsageWithDateInterval,
      error: null
    }
  } catch (err) {
    return { costAndUsage: null, error: err.message }
  }
}
