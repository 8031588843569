import { useRoutes } from 'react-router'

import Dashboard from './components/Dashboard'
import Costs from './components/Costs'
import Ec2Instances from './components/Ec2'
import GraphQLContainer from './components/Graphql'
import GraphQLEndpoints from './components/Graphql/GraphQLEndpoints'
import MigrationUsers from './components/MigrationUsers'
import TimestreamCosts from './components/TimestreamCosts'
import Translations from './components/Translations'
import SprintReports from './components/SprintReports'
import Fyi from './components/Fyi'
import FeatureFlags from './components/FeatureFlags'

const Routes = () => {
  const route = useRoutes([
    {
      path: '/',
      element: <Dashboard />
    },
    {
      path: '/graphql',
      element: <GraphQLContainer />,
      exact: true,
      children: [
        {
          path: ':env',
          element: <GraphQLEndpoints />
        }
      ]
    },
    {
      path: '/costs',
      element: <Costs />
    },
    {
      path: '/timestream-costs',
      element: <TimestreamCosts />
    },
    {
      path: '/ec2',
      element: <Ec2Instances />
    },
    {
      path: '/translations',
      element: <Translations />
    },
    {
      path: '/migration-users',
      element: <MigrationUsers />
    },
    // {
    //   path: '/sprint-reports',
    //   element: <SprintReports />
    // },
    {
      path: '/fyi',
      element: <Fyi />
    },
    {
      path: '/feature-flags',
      element: <FeatureFlags />
    }
  ])

  return route
}

export default Routes
