const toggleBase =
  'p-2 w-28 text-sm border-2 first:rounded-l last:rounded-r disabled:border-slate-100/50 disabled:text-slate-100/50'
const toggleUnselected = `${toggleBase} text-blue-200 border-blue-100 first:border-r-0 last:border-l-0`
const toggleSelected = `${toggleBase} text-blue-600 bg-blue-100/50 border-blue-500`

const tagBase = 'py-2 px-4 text-sm rounded h-10 mr-2 border-2 semibold'
export const tagUnselected = `text-blue-200 border-blue-100 ${tagBase}`
export const tagSelected = `border-blue-400 text-blue-600 bg-blue-100/50 ${tagBase}`

export const envsDefault = ['dev', 'prod', 'global']
export const envStrings = {
  dev: 'Development',
  prod: 'Production',
  global: 'Global'
}

export const viewStrings = {
  services: 'Services',
  resources: 'Resources',
  environments: 'Environments'
}

export const groupByOptions = {
  services: {
    key: 'service',
    type: 'TAG'
  },
  resources: {
    key: 'SERVICE',
    type: 'DIMENSION'
  },
  environments: {
    key: 'environment',
    type: 'TAG'
  }
}

export const getToggleClass = (listKeys, currentIndex, selected) => {
  const indexInList = listKeys.indexOf(selected)
  const position = indexInList - currentIndex

  switch (position) {
    case 0:
      return toggleSelected
    case -1:
      return `${toggleUnselected} border-l-0`
    case 1:
      return `${toggleUnselected} border-r-0`
    default:
      return toggleUnselected
  }
}

export const getMaxStartDate = () => {
  const maxStartDate = new Date(parseInt(Date.now() - 86400 * 1000))
    .toISOString()
    .split('T')[0]
  return maxStartDate
}

export const getMinStartDate = () => {
  const minStartDate = new Date(parseInt(Date.now() - 86400 * 1000 * 365))
    .toISOString()
    .split('T')[0]
  return minStartDate
}

export const getMaxEndDate = startDate => {
  const startMillis = new Date(startDate).valueOf()
  const threeMthsFromStart = startMillis + 86400 * 60000
  const endMillis =
    threeMthsFromStart > Date.now() ? Date.now() : threeMthsFromStart
  const maxEndDate = new Date(endMillis).toISOString().split('T')[0]
  return maxEndDate
}

export const getMinEndDate = startDate => {
  const startMillis = new Date(startDate).valueOf()
  const dayAfterStart = startMillis + 86400 * 1000
  const maxEndDate = new Date(dayAfterStart).toISOString().split('T')[0]
  return maxEndDate
}

export const getDateRange = data => {
  const dates = data.map(({ TimePeriod }) => TimePeriod.Start)
  return `${dates[0]} - ${dates[dates.length - 1]}`
}

export const getTotalAmount = data => {
  return data.reduce((runningTotal, currentArr) => {
    const groupSum = Object.keys(currentArr).reduce((groupTotal, key) => {
      if (key !== 'timePeriod' && key !== 'environment') {
        return groupTotal + parseFloat(currentArr[key])
      }
      return groupTotal
    }, 0)

    return runningTotal + groupSum
  }, 0)
}

const oneDayInMillis = 86400 * 1000

export const getAverageAmount = (data, costAndUsage) => {
  const totalAmount = getTotalAmount(data)
  const dateRange = getDateRange(costAndUsage)
  const [start, end] = dateRange.split(' - ')
  const startDateMillis = new Date(start).valueOf()
  const endDateMillis = new Date(end).valueOf() + oneDayInMillis
  const days = (endDateMillis - startDateMillis) / oneDayInMillis
  return totalAmount / days
}
