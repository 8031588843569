import { refreshInitiateAuth } from '../../api/cognito'

export const refreshIdToken = async (refreshToken, environment, setIdToken) => {
  const params = {
    refreshToken,
    environment
  }
  const { auth } = await refreshInitiateAuth(params)

  if (auth?.AuthenticationResult) {
    const { AuthenticationResult } = auth
    const key = `coretex-cognito-${environment}`
    const expiresInMillis = AuthenticationResult?.ExpiresIn * 1000
    const expiresAt = Date.now() + expiresInMillis
    if (!AuthenticationResult?.RefreshToken)
      AuthenticationResult['RefreshToken'] = refreshToken
    localStorage.setItem(
      key,
      JSON.stringify({ ...AuthenticationResult, expiresAt })
    )
    setIdToken(AuthenticationResult?.IdToken)
  }
}

export const getApiOptions = (apis, selectedEnv) => {
  const filteredByEnv = apis
    .filter(api => api.Environment === selectedEnv)
    .filter(api => api?.ApiMapping?.ApiMappingKey)

  return filteredByEnv.map(api => {
    const { DomainName, ApiMapping, Name, CorsConfiguration } = api
    return {
      name: Name,
      endpoint: `https://${DomainName}/${ApiMapping.ApiMappingKey}/graphql`,
      allowOrigins: CorsConfiguration?.AllowOrigins
    }
  })
}
