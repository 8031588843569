import { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'aws-amplify'
import { publicEncrypt } from 'public-encrypt'
import { Buffer } from 'buffer'

import { initiateAuth } from '../../api/cognito'
import { setErrors } from '../../actions/util'

const inputDefaultStyle = 'outline outline-1 outline-slate-200 p-1 rounded'

const SignInForm = ({ selectedEnv, setIdToken }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  const onChangeInput = ({ target }) => {
    if (target?.id === 'username') {
      setUsername(target.value)
    } else {
      setPassword(target.value)
    }
  }

  const onSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)

    const base64PublicKey = process.env.REACT_APP_AUTH_PUBLIC_KEY
    const publicKey = Buffer.from(base64PublicKey, 'base64').toString('ascii')
    const encrypted = publicEncrypt(publicKey, Buffer.from(password))

    const params = {
      username,
      password: encrypted.toString('base64'),
      environment: selectedEnv
    }

    const { auth, error } = await initiateAuth(params)
    setIsLoading(false)

    if (error) {
      const errors = error.split('\n')
      dispatch(setErrors(errors))
    }

    if (auth?.AuthenticationResult) {
      const { AuthenticationResult } = auth
      const key = `coretex-cognito-${selectedEnv}`
      const expiresInMillis = AuthenticationResult?.ExpiresIn * 1000
      const expiresAt = Date.now() + expiresInMillis
      localStorage.setItem(
        key,
        JSON.stringify({ ...AuthenticationResult, expiresAt })
      )
      setIdToken(AuthenticationResult?.IdToken)
    }
  }

  return (
    <Fragment>
      <div className='flex justify-center flex-col gap-2 items-center mt-12'>
        <h1 className='font-bold'>{I18n.get('Sign in')}</h1>
        <form onSubmit={onSubmit} className='flex flex-col gap-2 items-start'>
          <label className='pt-2 text-sm'>{I18n.get('Username')}</label>
          <input
            id='username'
            value={username}
            onChange={onChangeInput}
            className={inputDefaultStyle}
          />
          <label className='pt-2 text-sm'>{I18n.get('Password')}</label>
          <input
            type='password'
            id='password'
            value={password}
            onChange={onChangeInput}
            className={inputDefaultStyle}
          />
          <button
            disabled={isLoading}
            className='w-48 bg-teal-300 p-2 mt-6  disabled:bg-teal-500 disabled:cursor-not-allowed rounded enabled:hover:bg-teal-600 enabled:hover:text-white'
          >
            {isLoading ? I18n.get('Signing in...') : I18n.get('Sign in')}
          </button>
        </form>
      </div>
    </Fragment>
  )
}

export default SignInForm
