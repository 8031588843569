import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'

import Ec2Card from './Card'
import Loader from '../Shared/Loader'

import {
  getEC2Instances,
  getEC2InstancesRequested,
  getIsLoadingEC2,
  getEC2InstanceId
} from '../../reducers/selectors'
import { sendRequestEc2Instances } from '../../slices/ec2'

const Ec2Instances = () => {
  const dispatch = useDispatch()

  const instances = getEC2Instances()
  const requested = getEC2InstancesRequested()
  const loading = getIsLoadingEC2()
  const instanceId = getEC2InstanceId()

  if (!requested) dispatch(sendRequestEc2Instances())

  return (
    <div>
      <div>
        <h1 className='font-black text-2xl'>{I18n.get('EC2 Instances')}</h1>
        <p className='py-2'>{I18n.get('Available instances on AWS.')}</p>
      </div>
      {loading && !instanceId && (
        <Loader text='Fetching EC2 instances' fullpage={false} />
      )}
      <div className='flex flex-wrap justify-start pt-8 gap-4'>
        {instances.map(instance => {
          return <Ec2Card key={instance.InstanceId} {...instance} />
        })}
      </div>
    </div>
  )
}

export default Ec2Instances
