import { I18n } from 'aws-amplify'
import { useState } from 'react'

import CostAndUsage from './CostAndUsage'

import { getTags } from '../../reducers/selectors'

const Dashboard = () => {
  const tags = getTags()
  const [view, setView] = useState('services')

  function onChangeView(e) {
    e.preventDefault()
    setView(view === 'services' ? 'resources' : 'services')
  }

  return (
    <div>
      <div className='flex justify-between'>
        <div className='flex flex-col'>
          <h1 className='font-black text-2xl'>{I18n.get('Dashboard')}</h1>
          <p className='py-2'>
            {I18n.get(
              'View a comparison of costs across different Coretex services and AWS resources.'
            )}
          </p>
        </div>
        <div className='justify-self-center'>
          <button
            className='text-sm py-1 px-2 bg-slate-700 text-white rounded hover:bg-slate-300 hover:text-slate-900'
            onClick={onChangeView}
          >
            {view === 'services' ? 'View resources' : 'View services'}
          </button>
        </div>
      </div>
      <div className='py-8'>
        {tags?.environment?.map(env => (
          <CostAndUsage env={env} view={view} key={env} />
        ))}
      </div>
    </div>
  )
}

export default Dashboard
