import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getMissingtMigratedUsers } from '../../api/cognito'

export const sendFetchMissingMigratedUsers = createAsyncThunk(
  'sendFetchMissingMigratedUsers',
  async () => {
    return await getMissingtMigratedUsers()
  }
)

const cognitoSlice = createSlice({
  name: 'cognitoReducer',
  initialState: {
    error: null,
    users: {},
    isLoading: false,
    requested: false
  },
  extraReducers: builder => {
    builder
      .addCase(sendFetchMissingMigratedUsers.pending, state => {
        state.error = null
        state.isLoading = true
        state.requested = true
      })
      .addCase(sendFetchMissingMigratedUsers.fulfilled, (state, action) => {
        const { error, users } = action.payload
        if (!error) state.users = users
        state.error = error ?? null
        state.isLoading = false
      })
  }
})

export default cognitoSlice.reducer
