import { I18n } from 'aws-amplify'
import Modal from '../Shared/Modal'

const TranslationModal = ({ fileName, content, onClickClose }) => {
  const headings = { main: I18n.get('CSV Content'), sub: fileName }
  return (
    <Modal headings={headings} toggleView={onClickClose}>
      <table className='table-auto mt-4'>
        <thead>
          <tr>
            <th className='border-b font-medium p-4 pl-8 pt-0 pb-3 text-slate-400'>
              {content[0][0]}
            </th>
            <th className='border-b font-medium p-4 pl-8 pt-0 pb-3 text-slate-400'>
              {content[0][1]}
            </th>
          </tr>
        </thead>
        <tbody>
          {content.slice(1).map((item, index) => {
            return (
              <tr key={index} className='border-b border-slate-100 p-4 pl-8'>
                <td>{item[0]}</td>
                <td>{item[1]}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </Modal>
  )
}

export default TranslationModal
