import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { I18n } from 'aws-amplify'
import { InformationCircleIcon } from '@heroicons/react/24/solid'

import {
  getGraphQLApis,
  getIsLoadingGraphQLApis
} from '../../reducers/selectors'
import { sendFetchAllGraphQLApis } from '../../slices/api_gateway'

import SignInForm from './SignInForm'
import ShowGraphiQL from './ShowGraphiQL'

import { refreshIdToken, getApiOptions } from './utils'

const GraphQLEndpoints = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const [selectedEnv, setSelectedEnv] = useState(null)
  const [selectedEndpointIndex, setSelectedEndpointIndex] = useState(0)
  const [idToken, setIdToken] = useState(null)
  const [graphQLApiOptions, setGraphQLApiOptions] = useState([])

  const graphQLApis = getGraphQLApis()
  const isLoadingApis = getIsLoadingGraphQLApis()

  useEffect(() => {
    if (!isLoadingApis && graphQLApis?.length === 0) {
      dispatch(sendFetchAllGraphQLApis())
    }
  }, [graphQLApis, isLoadingApis])

  useEffect(() => {
    const authStorage = localStorage.getItem(`coretex-cognito-${selectedEnv}`)
    const authObj = JSON.parse(authStorage)

    if (authObj?.hasOwnProperty('IdToken')) {
      const isExpired = !authObj.expiresAt || Date.now() - authObj.expiresAt > 0
      if (isExpired) {
        setIdToken(null)
        refreshIdToken(authObj.RefreshToken, selectedEnv, setIdToken)
      } else {
        setIdToken(authObj.IdToken)
      }
    } else {
      setIdToken(null)
    }
  }, [selectedEnv])

  useEffect(() => {
    setSelectedEnv(location?.pathname?.split('/')[2])
    setSelectedEndpointIndex(0)
  }, [location?.pathname])

  useEffect(() => {
    const authStorage = localStorage.getItem(`coretex-cognito-${selectedEnv}`)
    const authObj = JSON.parse(authStorage)

    if (authObj?.hasOwnProperty('expiresAt')) {
      const timer = authObj.expiresAt - Date.now()
      setTimeout(() => {
        refreshIdToken(authObj.RefreshToken, selectedEnv, setIdToken)
      }, timer)
    }
  }, [idToken])

  useEffect(() => {
    if (graphQLApis?.length > 0) {
      const options = getApiOptions(graphQLApis, selectedEnv)
      setGraphQLApiOptions(options)
    }
  }, [graphQLApis, selectedEnv])

  const onSelectApiEndpoint = e => {
    setSelectedEndpointIndex(parseInt(e.target.value))
  }

  return (
    <div className='h-5/6 w-full'>
      {!idToken && (
        <SignInForm selectedEnv={selectedEnv} setIdToken={setIdToken} />
      )}
      {idToken && (
        <div className='w-full h-full pb-10 my-10'>
          {isLoadingApis && <p>{I18n.get('Loading GraphQL APIs...')}</p>}
          {!isLoadingApis && graphQLApiOptions.length > 0 && (
            <Fragment>
              <p className='inline pr-4'>{`${I18n.get(
                'Coretex API endpoint'
              )}:`}</p>
              <select
                onChange={onSelectApiEndpoint}
                value={selectedEndpointIndex}
                className='bg-slate-100/50 mr-2 mb-2 p-1 rounded min-w-64 inline'
                name='version'
                id='version'
              >
                {graphQLApiOptions.map((api, index) => (
                  <option key={api.name} value={index}>
                    {api.name}
                  </option>
                ))}
              </select>
              <div className='inline absolute'>
                <InformationCircleIcon className='h-7 w-7 absolute text-blue-500' />
                <div className='h-7 w-7 absolute z-10 overflow-hidden hover:overflow-visible'>
                  <div className='absolute rounded ml-7 bg-blue-500 text-white p-4 w-72 text-sm'>
                    <p className='font-bold mb-1'>
                      {I18n.get('CORS configuration')}
                    </p>
                    <p className='mb-4'>
                      {`${I18n.get('The endpoint')} `}
                      <strong>
                        {graphQLApiOptions[selectedEndpointIndex].name}
                      </strong>
                      {` ${I18n.get('allows the following origins.')}`}
                    </p>
                    <ul className='list-disc ml-5'>
                      {graphQLApiOptions[
                        selectedEndpointIndex
                      ]?.allowOrigins.map(origin => (
                        <li key={origin}>{origin}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <ShowGraphiQL
                selectedService={graphQLApiOptions[selectedEndpointIndex]}
                idToken={idToken}
              />
            </Fragment>
          )}
        </div>
      )}
    </div>
  )
}

export default GraphQLEndpoints
