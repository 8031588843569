import { useReducer, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import Modal from '../Shared/Modal'
import Loader from '../Shared/Loader'

import {
  sendCreateFeatureFlag,
  sendUpdateFeatureFlag,
  cleanUpserted
} from '../../slices/feature_flags'
import {
  getFeatureFlagUpserted,
  getFeatureFlagsLoading
} from '../../reducers/selectors'

let initialState = {
  featureFlagId: '',
  name: '',
  type: 'all'
}

const typeOptions = [
  { value: 'all', label: 'All' },
  { value: 'user', label: 'User' },
  { value: 'organization', label: 'Organization' }
]

function reducer(state, action) {
  if (action.type === 'update') {
    return { ...state, [action.name]: action.value }
  }

  if (action.type === 'setInit') {
    return { ...state, ...action.value }
  }
}

const inputClass = 'block my-1 border-2 border-slate-50 p-1 rounded w-full'
const labelClass = 'text-slate-300 font-medium'

export default function UpsertFeatureFlag({ featureFlag = null, closeModal }) {
  const isEdit = featureFlag ? true : false

  const [state, dispatch] = useReducer(reducer, initialState)
  const reduxDispatch = useDispatch()
  const upserted = getFeatureFlagUpserted()
  const loading = getFeatureFlagsLoading()

  if (isEdit && state.featureFlagId !== featureFlag?.featureFlagId) {
    dispatch({ type: 'setInit', value: featureFlag })
  }

  useEffect(() => {
    if (upserted?.featureFlagId === state?.featureFlagId) {
      reduxDispatch(cleanUpserted())
      closeModal()
    }
  }, [upserted?.featureFlagId, state?.featureFlagId, closeModal, reduxDispatch])

  const headings = {
    main: isEdit ? 'Edit feature flag' : 'Create feature flag',
    sub: isEdit
      ? 'Update the feature flag details'
      : 'Add a new feature flag on dev'
  }

  function onChangeInput({ target }) {
    const { value, name } = target
    dispatch({ type: 'update', value, name })
  }

  function onClickSubmit() {
    if (!isEdit) {
      // TODO check that ID is not already in use
      reduxDispatch(sendCreateFeatureFlag(state))
    }

    if (isEdit) {
      reduxDispatch(sendUpdateFeatureFlag(state))
    }
  }

  return (
    <Modal headings={headings} toggleView={closeModal}>
      {loading && <Loader text='Saving changes' />}
      <div className='flex flex-col gap-4'>
        <label>
          <span className={labelClass}>ID</span>
          <input
            name='featureFlagId'
            value={state.featureFlagId}
            onChange={onChangeInput}
            className={inputClass}
            disabled={isEdit}
          />
        </label>
        <label>
          <span className={labelClass}>Name</span>
          <input
            name='name'
            value={state.name}
            onChange={onChangeInput}
            className={inputClass}
          />
        </label>
        <label>
          <span className={labelClass}>Type</span>
          <select
            onChange={onChangeInput}
            value={state.type}
            className={inputClass}
            name='type'
          >
            {typeOptions.map(({ value, label }, index) => (
              <option key={`type-${value}`} value={value}>
                {label}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div className='flex justify-between'>
        <button
          onClick={closeModal}
          className='h-10 text-sm rounded px-4 py-2 bg-red-500 text-white hover:bg-red-700 mt-10'
        >
          Cancel
        </button>
        <button
          onClick={onClickSubmit}
          className='h-10 text-sm rounded px-4 py-2 bg-blue-500 text-white hover:bg-blue-700 mt-10'
          disabled={loading}
        >
          {isEdit ? 'Save' : 'Create'}
        </button>
      </div>
    </Modal>
  )
}
