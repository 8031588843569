import { Fragment, useEffect, useState } from 'react'
import { Hub, Auth, I18n } from 'aws-amplify'
import {
  AuthState,
  AUTH_STATE_CHANGE_EVENT,
  UI_AUTH_CHANNEL
} from '@aws-amplify/ui-components'
import { useNavigate, Link } from 'react-router-dom'
import {
  UserCircleIcon,
  ArrowRightOnRectangleIcon,
  ArrowsRightLeftIcon
} from '@heroicons/react/24/solid'

import SidebarItem from './SidebarItem'

import { useAuth } from '../../../contexts/auth-context'
import { getAllNavItems } from './utils'

const listClass = 'pl-2 text-sm leading-10 text-slate-600'

const Layout = ({ children }) => {
  const [navItems, setNavItems] = useState([])
  const [lockExpansion, setLockExpansion] = useState(true)
  const [expanded, setExpanded] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const navigate = useNavigate()
  const auth = useAuth()

  useEffect(() => {
    const handleWindowResize = () => {
      const isMobileView = window.innerWidth <= 1024
      setIsMobile(isMobileView)
    }
    window.addEventListener('resize', handleWindowResize)

    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  useEffect(() => {
    if (lockExpansion && !isMobile) {
      setExpanded(true)
    }
    if (isMobile && expanded) {
      setExpanded(false)
    }
  }, [isMobile, expanded, lockExpansion])

  useEffect(() => {
    const allNavItems = getAllNavItems()
    setNavItems(allNavItems)
  }, [])

  const onSignOut = async () => {
    await Auth.signOut()
    Hub.dispatch(UI_AUTH_CHANNEL, {
      event: AUTH_STATE_CHANGE_EVENT,
      message: AuthState.SignedOut
    })

    localStorage.removeItem('coretex-cognito-dev')
    localStorage.removeItem('coretex-cognito-prod')

    navigate('/')
  }

  const onClickLockedOpen = () => {
    const newState = !lockExpansion
    setLockExpansion(newState)
    setExpanded(newState)
  }

  const onSidebarHover = e => {
    if (!lockExpansion && !isMobile) {
      if (e?.type === 'mouseenter') {
        setExpanded(true)
      }
      if (e?.type === 'mouseleave') {
        setExpanded(false)
      }
    }
  }

  return (
    <Fragment>
      <main>
        <div className='flex w-full top-0 h-screen z-0'>
          <div
            className={`flex h-full justify-between flex-col bg-slate-100/25 transition-all ease-in-out duration-300 ${
              expanded ? 'w-96' : 'w-14 h:w-96'
            }`}
            onMouseEnter={onSidebarHover}
            onMouseLeave={onSidebarHover}
          >
            <div className='overflow-y-auto'>
              <div className='w-full my-6'>
                <Link
                  to='/'
                  className='h-12 text-slate-800 font-bold uppercase drop-shadow-xl border-b-2 border-slate-100/75 p-4 py-2 block w-full truncate'
                >
                  <span
                    className={`transition ease-in-out duration-300 absolute ${
                      expanded ? 'opacity-1' : 'opacity-0'
                    }`}
                  >
                    {I18n.get('Control Tower')}
                  </span>
                  <span
                    className={`transition ease-in-out duration-300 absolute ${
                      expanded ? 'opacity-0' : 'opacity-1'
                    }`}
                  >
                    {I18n.get('CT')}
                  </span>
                </Link>
              </div>
              <ul className={listClass}>
                {navItems?.length > 0 &&
                  navItems.map(item => (
                    <SidebarItem
                      navDetails={item}
                      key={item?.id}
                      expanded={expanded}
                    />
                  ))}
              </ul>
            </div>
            <div>
              {!isMobile && (
                <button
                  onClick={onClickLockedOpen}
                  className={`m-2 ${
                    lockExpansion ? 'bg-blue-500 text-white' : 'bg-slate-100'
                  } p-2 rounded`}
                >
                  <ArrowsRightLeftIcon className='h-5 w-5' />
                </button>
              )}
              <div className='p-2 border-t-2 border-slate-100/75 overflow-hidden'>
                <p className='font-light text-slate-600 text-xs truncate mr-2 w-full block'>
                  <UserCircleIcon className='w-7 h-7 inline m-1 opacity-50' />
                  <span
                    className={`m-2 transition ease-in-out duration-300 absolute ${
                      expanded ? 'opacity-1' : 'opacity-0'
                    }`}
                  >
                    {auth?.cognitoUser?.attributes?.email}
                  </span>
                </p>
                <button
                  type='button'
                  className='text-sm bg-slate-700 text-white rounded hover:bg-slate-200 hover:text-slate-900 w-full mb-4 mt-2 h-10'
                  onClick={onSignOut}
                >
                  <ArrowRightOnRectangleIcon className='h-5 w-5 mx-auto my-2 inline' />
                  <span
                    className={`m-2 transition-[opacity] ease-in-out duration-300 ${
                      expanded ? 'opacity-1' : 'opacity-0'
                    }`}
                  >
                    {I18n.get('Sign out')}
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className='w-full h-full p-14 overflow-scroll'>{children}</div>
        </div>
      </main>
    </Fragment>
  )
}

export default Layout
