/* eslint-disable react-hooks/rules-of-hooks */

import { useSelector } from 'react-redux'

const getStateWithSelector = (reducer, value) => {
  return useSelector(state => state.rootReducer[reducer][value])
}

export const getGraphQLApis = () => {
  return getStateWithSelector('apisReducer', 'graphqlApis')
}

export const getIsLoadingGraphQLApis = () => {
  return getStateWithSelector('apisReducer', 'isLoading')
}

export const getDashboardCostAndUsage = () => {
  return getStateWithSelector('costExplorerReducer', 'dashboardCostAndUsage')
}

export const getIsLoadingCognitoMissingMigrationUsers = () => {
  return getStateWithSelector('cognitoReducer', 'isLoading')
}

export const getRequestedMissingMigrationUsers = () => {
  return getStateWithSelector('cognitoReducer', 'requested')
}

export const getCognitoMissingMigrationUsers = () => {
  return getStateWithSelector('cognitoReducer', 'users')
}

export const getIsLoadingDashboardCostAndUsage = () => {
  return getStateWithSelector('costExplorerReducer', 'isLoading')
}

export const getCostsPageCostAndUsage = () => {
  return getStateWithSelector('costExplorerReducer', 'costsCostAndUsage')
}

export const getCostsPageEnvsFetched = () => {
  return getStateWithSelector('costExplorerReducer', 'costsEnvs')
}

export const getStartedDeployment = () => {
  return getStateWithSelector('deploymentReducer', 'deployment')
}

export const getIsLoadingDeployment = () => {
  return getStateWithSelector('deploymentReducer', 'isLoading')
}

export const getEC2Instances = () => {
  return getStateWithSelector('ec2Reducer', 'instances')
}

export const getEC2InstancesRequested = () => {
  return getStateWithSelector('ec2Reducer', 'instancesRequested')
}

export const getEC2InstanceId = () => {
  return getStateWithSelector('ec2Reducer', 'instanceId')
}

export const getIsLoadingEC2 = () => {
  return getStateWithSelector('ec2Reducer', 'isLoading')
}

export const getFeatureFlagsSyncing = () => {
  return getStateWithSelector('featureFlagsReducer', 'isSyncing')
}

export const getFeatureFlagsMessage = () => {
  return getStateWithSelector('featureFlagsReducer', 'message')
}

export const getFeatureFlags = () => {
  return getStateWithSelector('featureFlagsReducer', 'featureFlags')
}

export const getFeatureFlagsRequested = () => {
  return getStateWithSelector('featureFlagsReducer', 'featureFlagsRequested')
}

export const getFeatureFlagsLoading = () => {
  return getStateWithSelector('featureFlagsReducer', 'loading')
}

export const getFeatureFlagUpserted = () => {
  return getStateWithSelector('featureFlagsReducer', 'upserted')
}

export const getFyiSyncing = () => {
  return getStateWithSelector('fyiReducer', 'isSyncing')
}

export const getFyiMessage = () => {
  return getStateWithSelector('fyiReducer', 'message')
}

export const getDevOrganizations = () => {
  return getStateWithSelector(
    'managementOrganizationReducer',
    'organizationsDev'
  )
}

export const getProdOrganizations = () => {
  return getStateWithSelector(
    'managementOrganizationReducer',
    'organizationsProd'
  )
}

export const getTags = () => {
  return getStateWithSelector('resourceGroupsTaggingReducer', 'tags')
}

export const getTimestreamCosts = () => {
  return getStateWithSelector('timestreamCostsReducer', 'costs')
}

export const getTimestreamFetched = () => {
  return getStateWithSelector('timestreamCostsReducer', 'fetched')
}

export const getTimestreamLoading = () => {
  return getStateWithSelector('timestreamCostsReducer', 'loading')
}

export const getMissingTranslations = () => {
  return getStateWithSelector('translationsReducer', 'translations')
}

export const getTranslationsLoading = () => {
  return getStateWithSelector('translationsReducer', 'isLoading')
}

export const getTranslationsRequested = () => {
  return getStateWithSelector('translationsReducer', 'translationsRequested')
}

export const getZenhubReports = () => {
  return getStateWithSelector('zenhubReportsReducer', 'reports')
}
export const getZenhubReportsLoading = () => {
  return getStateWithSelector('zenhubReportsReducer', 'isLoading')
}

export const getZenhubSprints = () => {
  return getStateWithSelector('zenhubReportsReducer', 'sprints')
}

export const getIsRequestingReport = () => {
  return getStateWithSelector('zenhubReportsReducer', 'isRequestingReport')
}

export const getZenhubReportData = () => {
  return getStateWithSelector('zenhubReportsReducer', 'reportData')
}

export const getZenhubReportType = () => {
  return getStateWithSelector('zenhubReportsReducer', 'reportType')
}

export const getIsLoadingZenhubReportData = () => {
  return getStateWithSelector('zenhubReportsReducer', 'isLoadingReportData')
}

export const getBannerErrors = () => {
  return getStateWithSelector('utilReducer', 'errors')
}

export const getBannerErrorNew = () => {
  return getStateWithSelector('utilReducerNew', 'error')
}

export const getBannerSuccessMsg = () => {
  return getStateWithSelector('utilReducer', 'success')
}

export const getBannerSuccessNew = () => {
  return getStateWithSelector('utilReducerNew', 'success')
}

export const getGlobalIsLoading = () => {
  return getStateWithSelector('utilReducer', 'globalIsLoading')
}

export const getSitesDev = () => {
  return getStateWithSelector('managementSiteReducer', 'sitesDev')
}

export const getSitesProd = () => {
  return getStateWithSelector('managementSiteReducer', 'sitesProd')
}
