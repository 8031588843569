import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getCostAndUsageWithDateInterval } from '../../api/cost_explorer'
import { setErrors } from '../../actions/util'

const currentViewStrings = {
  TAG: 'services',
  DIMENSION: 'resources'
}

export const requestCostAndUsageForDashboard = createAsyncThunk(
  'requestCostAndUsageForDashboard',
  async (params, { dispatch }) => {
    const { costAndUsage, error } = await getCostAndUsageWithDateInterval(
      params
    )
    if (error) dispatch(setErrors(error))

    return { costAndUsage, error }
  }
)

export const requestCostAndUsageForCosts = createAsyncThunk(
  'requestCostAndUsageForCosts',
  async (params, { dispatch }) => {
    const { costAndUsage, error } = await getCostAndUsageWithDateInterval(
      params
    )
    if (error) dispatch(setErrors(error))

    return { costAndUsage, error }
  }
)

const costExplorerSlice = createSlice({
  name: 'costExplorerReducer',
  initialState: {
    error: null,
    dashboardCostAndUsage: {},
    costsCostAndUsage: {},
    costsEnvs: [],
    isLoading: false
  },
  extraReducers: builder => {
    builder
      .addCase(requestCostAndUsageForDashboard.pending, (state, action) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(requestCostAndUsageForDashboard.fulfilled, (state, action) => {
        let dashboardCostAndUsage = { ...state.dashboardCostAndUsage }
        const { costAndUsage, error } = action.payload
        const { tags, groupBy } = action.meta.arg
        const env = tags.values[0]
        const view = groupBy?.type

        const currentView = currentViewStrings[view]
        if (!error) {
          let viewData = {}
          if (dashboardCostAndUsage.hasOwnProperty(currentView)) {
            viewData = dashboardCostAndUsage[currentView]
          }
          viewData[env] = costAndUsage
          dashboardCostAndUsage[currentView] = viewData
        }

        state.dashboardCostAndUsage = dashboardCostAndUsage
        state.error = error ?? null
        state.isLoading = false
      })
      .addCase(requestCostAndUsageForCosts.pending, (state, action) => {
        const envs = action?.meta?.arg?.tags?.values
        state.isLoading = true
        state.error = null
        state.costsEnvs = envs
      })
      .addCase(requestCostAndUsageForCosts.fulfilled, (state, action) => {
        const { costAndUsage, error } = action.payload
        state.costsCostAndUsage = costAndUsage
        state.error = error ?? null
        state.isLoading = false
      })
  }
})

export default costExplorerSlice.reducer
