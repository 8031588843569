import { useState } from 'react'
import { ResponsiveBar } from '@nivo/bar'

import CustomLegend from './CustomLegend'

import useMediaQuery from '../../../hooks/useMediaQuery'

const CHART_HEIGHT_DESKTOP = 750
const CHART_HEIGHT_BASE_MOBILE = 500
const LEGEND_ITEM_HEIGHT = 22
const MARGIN_BOTTOM_BASE = 50

const ResponsiveBarChart = ({
  data,
  keys,
  indexBy = 'timePeriod',
  customTooltip,
  groupMode = 'stacked'
}) => {
  const isAboveBreakpoint = useMediaQuery('min-width: 1000px')
  const [itemsPerCol, setItemsPerCol] = useState(keys.length)
  const chartHeightMobile =
    CHART_HEIGHT_BASE_MOBILE + itemsPerCol * LEGEND_ITEM_HEIGHT
  const marginBottom = isAboveBreakpoint
    ? MARGIN_BOTTOM_BASE
    : itemsPerCol * LEGEND_ITEM_HEIGHT + MARGIN_BOTTOM_BASE

  const chartHeight = isAboveBreakpoint
    ? `${CHART_HEIGHT_DESKTOP}px`
    : `${chartHeightMobile}px`
  const marginRight = isAboveBreakpoint ? 300 : 10

  return (
    <div
      className='my-4'
      style={{
        height: chartHeight
      }}
    >
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy={indexBy}
        groupMode={groupMode}
        margin={{ top: 50, right: marginRight, bottom: marginBottom, left: 10 }}
        padding={groupMode === 'stacked' ? 0.3 : 0.1}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'nivo' }}
        layers={['grid', 'axes', 'bars', CustomLegend]}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.6]]
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickRotation: Object.keys(data)?.length > 20 ? 30 : 0
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 1.6]]
        }}
        legends={[
          {
            isMobile: !isAboveBreakpoint,
            setItemsPerCol,
            itemsPerCol
          }
        ]}
        tooltip={customTooltip}
        role='application'
      />
    </div>
  )
}

export default ResponsiveBarChart
