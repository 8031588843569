import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { requestCostAndUsageForDashboard } from '../../slices/cost_explorer'
import {
  getDashboardCostAndUsage,
  getIsLoadingDashboardCostAndUsage,
  getGlobalIsLoading
} from '../../reducers/selectors'

import Loader from '../Shared/Loader'
import Chart from '../Shared/Chart'
import { I18n } from 'aws-amplify'

const sectionStrings = {
  dev: 'Development',
  prod: 'Production',
  global: 'Global'
}

function cleanService(service) {
  if (service.includes('service$')) {
    const nextService = service.split('$')[1]
    if (nextService.length === 0) {
      return 'other'
    }

    return nextService
  }

  return service
}

const CostAndUsage = ({ env, view }) => {
  const dispatch = useDispatch()
  const dashboardCostAndUsage = getDashboardCostAndUsage()
  const isLoading = getIsLoadingDashboardCostAndUsage()
  const globalIsLoading = getGlobalIsLoading()

  const [keys, setKeys] = useState([])
  const [data, setData] = useState([])
  const [amounts, setAmounts] = useState({})
  const [timePeriods, setTimePeriods] = useState([])
  const [currentViewData, setCurrentViewData] = useState([])

  const tooltipRef = useRef(null)
  const [offset, setOffset] = useState({ top: 0 })

  const setTooltipOffset = () => {
    const tooltipRect = tooltipRef.current.getBoundingClientRect()
    const viewHeight = window.innerHeight
    const { height: tooltipHeight, top, bottom } = tooltipRect
    if (viewHeight < bottom && top > tooltipHeight) setOffset({ bottom: 0 })
    if (top < 0) setOffset({ top: 0 })
  }

  useEffect(() => {
    const params = {
      tags: {
        key: 'environment',
        values: [env]
      },
      groupBy: {
        key: view === 'services' ? 'service' : 'SERVICE',
        type: view === 'services' ? 'TAG' : 'DIMENSION'
      }
    }

    if (
      !globalIsLoading &&
      (!dashboardCostAndUsage.hasOwnProperty(view) ||
        currentViewData[env]?.length < 1)
    ) {
      dispatch(requestCostAndUsageForDashboard(params))
    }
  }, [
    dispatch,
    env,
    view,
    dashboardCostAndUsage,
    currentViewData,
    globalIsLoading
  ])

  useEffect(() => {
    if (dashboardCostAndUsage.hasOwnProperty(view)) {
      setCurrentViewData(dashboardCostAndUsage[view])
    }
  }, [dashboardCostAndUsage, view])

  useEffect(() => {
    if (currentViewData[env]) {
      const groupKeys = currentViewData[env]?.map(({ Groups }) => {
        return Groups.map(({ Services }) => {
          let servicesIndex = 0
          if (Services?.length > 1) {
            const envIndex = Services?.findIndex(item =>
              item.includes('environment$')
            )
            if (envIndex === 0) servicesIndex = 1
          }
          return cleanService(Services[servicesIndex])
        })
      })

      const nextKeys = [...new Set(groupKeys.flat())]
      nextKeys.sort().reverse()
      setKeys(nextKeys)

      const groupedData = currentViewData[env]?.map(
        ({ TimePeriod, Groups }) => {
          const data = Groups.reduce((acc, item) => {
            const { Services, Amount } = item
            let servicesIndex = 0
            if (Services?.length > 1) {
              const envIndex = Services?.findIndex(item =>
                item.includes('environment$')
              )
              if (envIndex === 0) servicesIndex = 1
            }
            acc[cleanService(Services[servicesIndex])] =
              parseFloat(Amount).toFixed(2)
            return acc
          }, {})

          return {
            timePeriod: TimePeriod.Start,
            ...data
          }
        }
      )

      const newAmounts = currentViewData[env]?.reduce((acc, item) => {
        const { TimePeriod, Groups } = item
        if (!acc.hasOwnProperty('total')) {
          acc['total'] = 0
        }

        if (!acc.hasOwnProperty(TimePeriod.Start)) {
          acc[TimePeriod.Start] = 0
        }

        const amountForDay = Groups.reduce((acc, item) => {
          const { Amount } = item
          acc += parseFloat(Amount)
          return acc
        }, 0)

        acc[TimePeriod.Start] = amountForDay
        acc.total += amountForDay
        return acc
      }, {})

      const newTimePeriods = [
        currentViewData[env][0]?.TimePeriod['Start'],
        currentViewData[env][currentViewData[env].length - 1]?.TimePeriod['End']
      ]
      setTimePeriods(newTimePeriods)
      setData(groupedData)
      setAmounts(newAmounts)
    }
  }, [currentViewData, env, view])

  if (globalIsLoading) return null

  if (isLoading) {
    return (
      <div className='m-32 overflow-hidden'>
        <Loader
          fullpage={false}
          text={`${I18n.get('Loading')} ${sectionStrings[env]}`}
        />
      </div>
    )
  }

  if (keys.length === 0 || timePeriods.length === 0) {
    return null
  }

  const gridCellStyle = 'px-1'

  const customTooltip = ({ data: tooltipData, id, color }) => {
    const { timePeriod, ...rest } = tooltipData

    setTimeout(() => setTooltipOffset(), 300)

    const insertRow = (key, value, extraClasses = '') => {
      let rowClassStyle = `flex justify-between ${extraClasses}`
      let rowStyle = {}

      if (key === id) {
        const newClass = `${rowClassStyle} font-bold`
        rowClassStyle = newClass
        rowStyle = { color }
      }

      return (
        <div className={rowClassStyle} style={rowStyle} key={key}>
          <p>{key}</p>
          <p className='w-12 text-left'>{`$${value}`}</p>
        </div>
      )
    }

    const dailyTotal = Object.keys(rest)
      .reduce((acc, key) => acc + parseFloat(rest[key]), 0)
      .toFixed(2)

    const dailyTotalRounded = parseFloat(
      Math.round(dailyTotal * 100) / 100
    ).toFixed(2)

    const sortedKeys = Object.keys(rest).sort()

    return (
      <div
        style={offset}
        ref={tooltipRef}
        className='mx-4 absolute p-4 bg-slate-800 text-slate-100 font-light text-sm w-96'
      >
        <p className='text-white mb-4 font-bold text-base'>{`${I18n.get(
          'Daily costs for '
        )} ${timePeriod}`}</p>
        {sortedKeys.map(key => {
          return insertRow(key, tooltipData[key])
        })}
        <hr className='mt-4 mb-2' />
        {insertRow(
          I18n.get('Daily total'),
          dailyTotalRounded,
          'text-white font-extrabold'
        )}
      </div>
    )
  }

  return (
    <div className='mb-20'>
      <h2 className='text-lg font-semibold mb-4 text-slate-800'>
        {sectionStrings[env]}
      </h2>
      <div className='grid grid-cols-4 border-slate-200 bg-slate-100/25 p-2'>
        <p className={`col-span-2 ${gridCellStyle}`}>Date range</p>
        <p className={gridCellStyle}>Total cost</p>
        <p className={gridCellStyle}>Average daily cost</p>
        <p className={`col-span-2 ${gridCellStyle}`}>
          <strong>
            {timePeriods[0]} &ndash; {timePeriods[1]}
          </strong>
        </p>
        <p className={gridCellStyle}>
          <strong>${parseFloat(amounts.total).toFixed(2)}</strong>
        </p>
        <p className={gridCellStyle}>
          <strong>${parseFloat(amounts.total / data.length).toFixed(2)}</strong>
        </p>
      </div>
      <Chart data={data} keys={keys} customTooltip={customTooltip} />
    </div>
  )
}

export default CostAndUsage
