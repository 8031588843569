import { columnHeaders } from './utils'

const Row = ({ data }) => {
  function insertCellData(column) {
    switch (column) {
      case 'data':
        return data?.date
      case 'metered':
        return `${parseFloat(data?.metered).toFixed(2)} GB`
      case 'scanned':
        return `${parseFloat(data?.scanned).toFixed(2)} GB`
      case 'expenses':
        return `$${parseFloat(data?.expenses).toFixed(2)}`
      default:
        return null
    }
  }

  return (
    <tr>
      {Object.keys(columnHeaders).map((key, index) => (
        <td
          className='truncate py-2 border-b-2 border-slate-100/50 h-16'
          key={`${key}-${index}`}
        >
          {insertCellData(key)}
        </td>
      ))}
    </tr>
  )
}

export default Row
