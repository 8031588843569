import { I18n } from 'aws-amplify'
import { Fragment, useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'

import Loader from '../Shared/Loader'

import { setErrors, clearErrors } from '../../actions/util'

import {
  changePasswordSchema,
  forgotPasswordSchema,
  passwordSchema
} from './validations'
import { useAuth } from '../../contexts/auth-context'
import { getFormErrors } from '../../utils'

const labels = {
  code: I18n.get('Code'),
  oldPassword: I18n.get('Old password'),
  newPassword: I18n.get('New password'),
  confirmPassword: I18n.get('Confirm new password')
}

const placeholders = {
  code: I18n.get('Enter the code sent to your email'),
  oldPassword: I18n.get('Enter your old password'),
  newPassword: I18n.get('Enter your new password'),
  confirmPassword: I18n.get('Confirm your new password')
}

const initalState = {
  code: '',
  oldPassword: '',
  newPassword: '',
  confirmPassword: ''
}

const reducer = (state, action) => {
  if (action.type === 'update') {
    return { ...state, [action.id]: action.value }
  }
  return state
}

const inputDefaultStyle =
  'outline outline-1 outline-slate-100/60 p-4 rounded w-full bg-white h-12 text-slate-500 font-light mt-2 bg-slate-100/10'

function PasswordReset({
  type = 'changePassword',
  hubData = {},
  needOldPassword = false,
  needCode = false
}) {
  const [loading, setIsLoading] = useState(false)
  const [state, dispatch] = useReducer(reducer, initalState)
  const auth = useAuth()
  const reduxDispatch = useDispatch()

  const onChangeInput = e => {
    const { id, value } = e.currentTarget
    dispatch({ type: 'update', id, value })
  }

  const onSubmit = async e => {
    e.preventDefault()
    reduxDispatch(clearErrors())
    setIsLoading(true)
    if (type === 'UserForgotPassword') {
      await onForgotPassword()
    } else if (type === 'changePassword') {
      await onChangePassword()
    } else if (type === 'NewPasswordRequired') {
      await onCompleteNewPassword()
    }
    setIsLoading(false)
  }

  const onChangePassword = async () => {
    try {
      await changePasswordSchema.validate(state, { abortEarly: false })
      await auth.changePassword(
        state.oldPassword,
        state.newPassword,
        hubData.user
      )
    } catch (err) {
      const bannerErrors = getFormErrors(err)
      reduxDispatch(setErrors(bannerErrors))
    }
  }

  const onForgotPassword = async () => {
    try {
      await forgotPasswordSchema.validate(state, { abortEarly: false })
      await auth.forgotPasswordSubmit(
        hubData.username,
        state.code,
        state.newPassword
      )
    } catch (error) {
      const bannerErrors = getFormErrors(error)
      reduxDispatch(setErrors(bannerErrors))
    }
  }

  const onCompleteNewPassword = async () => {
    try {
      await passwordSchema.validate(state, { abortEarly: false })
      await auth.completeNewPassword(state.newPassword)
    } catch (error) {
      const bannerErrors = getFormErrors(error)
      reduxDispatch(setErrors(bannerErrors))
    }
  }

  const getShowField = key => {
    if (
      (key === 'code' && !needCode) ||
      (key === 'oldPassword' && !needOldPassword)
    ) {
      return false
    }

    return true
  }

  return (
    <div className='text-center'>
      <h2 className='text-lg mt-2'>{I18n.get('Reset your password')}</h2>
      <form onSubmit={onSubmit} className='flex flex-col items-start w-96'>
        {Object.keys(state).map(key => {
          return (
            getShowField(key) && (
              <Fragment key={key}>
                <label className='mt-16'>{labels[key]}</label>
                <input
                  type={key === 'code' ? 'text' : 'password'}
                  id={key}
                  value={state[key]}
                  onChange={onChangeInput}
                  className={inputDefaultStyle}
                  placeholder={placeholders[key]}
                />
              </Fragment>
            )
          )
        })}
        <button
          type='submit'
          className='p-2 mt-16 mb-8 bg-slate-500 text-white rounded w-full mx-auto hover:bg-slate-100 hover:text-slate-800 drop-shadow-[0_12px_18px_rgba(73,93,143,0.3)]'
        >
          {I18n.get('Save password')}
        </button>
      </form>
      {loading && <Loader text='Updating password' />}
    </div>
  )
}

export default PasswordReset
