import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getSites } from '../../api/sites'

export const fetchSites = createAsyncThunk(
  'fetchSiteHierarchy',
  async params => {
    const devResponse = await getSites({ isDev: true })
    const prodResponse = await getSites({ isDev: false })

    const error = devResponse?.error ?? prodResponse?.error
    return {
      sitesDev: devResponse?.sites,
      sitesProd: prodResponse?.sites,
      error
    }
  }
)

const managementSiteSlice = createSlice({
  name: 'managementSiteReducer',
  initialState: {
    sitesDev: [],
    sitesProd: [],
    error: null
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSites.pending, (state, action) => {
        state.error = null
      })
      .addCase(fetchSites.fulfilled, (state, action) => {
        const { sitesDev, sitesProd } = action?.payload ?? {}
        state.error = action?.payload?.error ?? null
        state.sitesDev = sitesDev
        state.sitesProd = sitesProd
      })
  }
})

export default managementSiteSlice.reducer
