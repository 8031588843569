import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getTimestreamCostsByOrganizationId } from '../../api/timestream_costs'

export const requestTimestreamCosts = createAsyncThunk(
  'fetchTimestreamCosts',
  async params => {
    const { organizationId, isDev } = params

    const { timestreamCosts, error } = await getTimestreamCostsByOrganizationId(
      {
        organizationId,
        isDev
      }
    )

    return {
      timestreamCosts,
      isDev,
      error
    }
  }
)

const timestreamCostsSlice = createSlice({
  name: 'timestreamCostsReducer',
  initialState: {
    error: null,
    costs: {
      dev: {},
      prod: {}
    },
    loading: false,
    fetched: false
  },
  extraReducers: builder => {
    builder
      .addCase(requestTimestreamCosts.pending, (state, action) => {
        state.loading = true
      })
      .addCase(requestTimestreamCosts.fulfilled, (state, action) => {
        const { timestreamCosts, isDev, error } = action?.payload
        const currentEnv = isDev ? 'dev' : 'prod'
        let updatedCosts = { ...state.costs }

        const { organizationId, costs } = timestreamCosts
        updatedCosts[currentEnv][organizationId] = costs

        state.fetched = true
        state.loading = false
        state.error = error ?? null
        state.costs = updatedCosts
      })
  }
})

export default timestreamCostsSlice.reducer
