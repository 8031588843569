import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getTagValues } from '../../api/resource_groups_tagging'
import { setErrors } from '../../actions/util'

export const sendFetchInitTagValues = createAsyncThunk(
  'sendFetchInitTagValues',
  async () => {
    const tags = ['environment', 'service', 'siteId', 'organizationId']

    const tagRequests = tags.map(tag => getTagValues({ tag }))
    const tagsResponses = await Promise.all(tagRequests)

    const tagsData = tagsResponses.reduce(
      (acc, item, index) => {
        const tag = tags[index]
        const { values, error } = item
        if (!acc.error && error) acc.error = error
        const formattedValues = values.map(({ Name }) => Name)
        acc.valuesByTag[tag] = formattedValues

        return acc
      },
      { valuesByTag: {}, error: null }
    )
    return tagsData
  }
)

const resourceGroupsTaggingSlice = createSlice({
  name: 'resourceGroupsTaggingReducer',
  initialState: {
    error: null,
    tags: {},
    isLoading: false
  },
  extraReducers: builder => {
    builder
      .addCase(sendFetchInitTagValues.pending, state => {
        state.isLoading = true
        state.error = null
      })
      .addCase(sendFetchInitTagValues.fulfilled, (state, action) => {
        const { valuesByTag, error } = action.payload
        const tags = { ...valuesByTag }
        const { environment } = valuesByTag

        tags['environment'] = environment.filter(env =>
          ['dev', 'prod', 'global'].includes(env)
        )

        state.tags = tags
        state.error = error ?? null
        state.isLoading = false
      })
  }
})

export default resourceGroupsTaggingSlice.reducer
