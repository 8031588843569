import { API, graphqlOperation } from 'aws-amplify'

import { API_NAME, GRAPHQL_ROUTE } from '../config'
import { getIdToken } from '../utils'

export async function getEC2Instances(params) {
  try {
    const query = /* GraphQL */ `
      query getEC2Instances {
        getEC2Instances {
          Architecture
          InstanceId
          InstanceType
          Name
          PlatformDetails
          State
        }
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(query, params)
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return {
      instances: response.data.getEC2Instances,
      error: null
    }
  } catch (err) {
    return { instances: null, error: err.message }
  }
}

export async function getEC2Instance(params) {
  try {
    const query = /* GraphQL */ `
      query getEC2Instance($InstanceId: String!) {
        getEC2Instance(InstanceId: $InstanceId) {
          Architecture
          InstanceId
          InstanceType
          Name
          PlatformDetails
          State
        }
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(query, params)
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return {
      instance: response.data.getEC2Instance,
      error: null
    }
  } catch (err) {
    return { instance: null, error: err.message }
  }
}

export async function startEC2Instance(params) {
  try {
    const mutation = /* GraphQL */ `
      mutation startEC2Instance($InstanceId: String!) {
        startEC2Instance(InstanceId: $InstanceId) {
          InstanceId
          State
        }
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(mutation, params)
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return {
      instance: response.data.startEC2Instance,
      error: null
    }
  } catch (err) {
    return { instance: null, error: err.message }
  }
}

export async function stopEC2Instance(params) {
  try {
    const mutation = /* GraphQL */ `
      mutation stopEC2Instance($InstanceId: String!) {
        stopEC2Instance(InstanceId: $InstanceId) {
          InstanceId
          State
        }
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(mutation, params)
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return {
      instance: response.data.stopEC2Instance,
      error: null
    }
  } catch (err) {
    return { instance: null, error: err.message }
  }
}
