import { useState, useEffect, Fragment, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'aws-amplify'
import {
  CalendarDaysIcon,
  FunnelIcon,
  ChartBarIcon
} from '@heroicons/react/24/solid'

import Chart from '../Shared/Chart'
import Loader from '../Shared/Loader'
import Tooltip from './tooltip'

import useDebounce from '../../hooks/useDebounce'
import { requestCostAndUsageForCosts } from '../../slices/cost_explorer'
import {
  getCostsPageCostAndUsage,
  getGlobalIsLoading,
  getIsLoadingDashboardCostAndUsage,
  getTags,
  getSitesDev,
  getSitesProd,
  getDevOrganizations,
  getProdOrganizations,
  getCostsPageEnvsFetched
} from '../../reducers/selectors'
import { fetchSites } from '../../slices/management/sites'
import { fetchOrganizations } from '../../slices/management/organizations'

import {
  tagSelected,
  tagUnselected,
  envsDefault,
  envStrings,
  viewStrings,
  groupByOptions,
  getToggleClass,
  getMaxEndDate,
  getMaxStartDate,
  getMinStartDate,
  getMinEndDate,
  getDateRange,
  getAverageAmount,
  getTotalAmount
} from './utils'
import { dateRangeSchema } from '../../utils/costsValidation'

const gridCellStyle = 'px-1'
const filterIconClass =
  'w-9 p-1 mr-2 hover:cursor-pointer hover:bg-blue-200 hover:border-blue-400 hover:text-blue-400 border-2 rounded'
const filterIconSelectedClass = `${filterIconClass}  border-blue-300 text-blue-500 bg-blue-100/50`
const filterIconUnselectedClass = `${filterIconClass} border-blue-100 text-blue-100`

function cleanService(service) {
  if (service?.includes('$')) {
    const nextService = service.split('$')[1]
    if (nextService.length === 0) {
      return 'other'
    }

    return nextService
  }

  return service
}

function reducer(state, action) {
  switch (action.type) {
    case 'update':
      return { ...state, [action.field]: action.value }
    case 'clearError':
      return { ...state, [action.field]: null }
    case 'remove':
      const removeArr = [...state[action.field]].filter(
        item => item !== action.value
      )
      return { ...state, [action.field]: removeArr }
    case 'add':
      const addArr = [...state[action.field]]
      addArr.push(action.value)
      return { ...state, [action.field]: addArr.sort() }
    default:
      return { ...state }
  }
}

const initValues = {
  startDate: new Date(parseInt(Date.now() / 1000 - 86400 * 7) * 1000)
    .toISOString()
    .split('T')[0],
  endDate: new Date().toISOString().split('T')[0],
  dateError: null,
  filteredKeys: [],
  isStacked: true,
  isIndexedByTime: true,
  appliedView: '',
  filterBySite: 'all',
  filterByOrg: 'all'
}

const Costs = () => {
  const [keys, setKeys] = useState([])
  const [data, setData] = useState([])
  const [envs, setEnvs] = useState(envsDefault)
  const [view, setView] = useState('services')
  const [showFilterOptions, setShowFilterOptions] = useState(false)
  const [sitesOptions, setSitesOptions] = useState([])
  const [state, dispatch] = useReducer(reducer, initValues)
  const reduxDispatch = useDispatch()
  const debouncedStartDate = useDebounce(state.startDate, 300)
  const debouncedEndDate = useDebounce(state.endDate, 300)

  const costAndUsage = getCostsPageCostAndUsage()
  const globalIsLoading = getGlobalIsLoading()
  const isLoading = getIsLoadingDashboardCostAndUsage()
  const tags = getTags()
  const sitesDev = getSitesDev()
  const sitesProd = getSitesProd()
  const devOrganizations = getDevOrganizations()
  const prodOrganizations = getProdOrganizations()
  const envsFetched = getCostsPageEnvsFetched()

  const fetchLatestConfigData = () => {
    const params = {
      groupBy: [groupByOptions[view]],
      timePeriod: {
        start: state.startDate,
        end: state.endDate
      }
    }

    if (state.filterBySite === 'all' && state.filterByOrg === 'all') {
      params['tags'] = {
        key: 'environment',
        values: envs
      }
    }

    if (state.filterByOrg !== 'all' && state.filterBySite === 'all') {
      params['tags'] = {
        key: 'organizationId',
        values: [state.filterByOrg]
      }
    }

    if (state.filterBySite !== 'all') {
      params['tags'] = {
        key: 'siteId',
        values: [state.filterBySite]
      }
    }

    if (view !== 'environments')
      params.groupBy.push(groupByOptions.environments)

    if (view === 'environments' && !state.isIndexedByTime) {
      dispatch({
        type: 'update',
        field: 'isIndexedByTime',
        value: true
      })
    }

    // TODO: add monthly granularity if date range is too big
    // const startDateObj = new Date(state.startDate)
    // const endDateObj = new Date(state.endDate)
    // const numOfDays = (endDateObj.valueOf() - startDateObj.valueOf()) / 86400000
    // if (numOfDays > 31) {
    //   params['granularity'] = 'MONTHLY'
    // }
    dispatch({ type: 'update', field: 'appliedView', value: view })
    reduxDispatch(requestCostAndUsageForCosts(params))
  }

  useEffect(() => {
    if (devOrganizations?.length === 0 || prodOrganizations?.length === 0) {
      reduxDispatch(fetchOrganizations())
    }
  }, [devOrganizations?.length, prodOrganizations?.length, dispatch])

  useEffect(() => {
    const params = {
      tags: {
        key: 'environment',
        values: envs
      },
      groupBy: [groupByOptions[view]]
    }

    if (view !== 'environments')
      params.groupBy.push(groupByOptions.environments)
    dispatch({ type: 'update', field: 'appliedView', value: view })
    reduxDispatch(requestCostAndUsageForCosts(params))
  }, [])

  useEffect(() => {
    if (sitesDev?.length === 0 && sitesProd?.length === 0) {
      reduxDispatch(fetchSites())
    }
  }, [sitesDev?.length, sitesProd?.length, reduxDispatch])

  useEffect(() => {
    if (sitesDev?.length === 0 && sitesProd?.length === 0) {
      reduxDispatch(fetchSites())
    }
  }, [sitesDev?.length, sitesProd?.length, reduxDispatch])

  useEffect(() => {
    let optionsToShow = tags?.siteId
    if (state.filterByOrg !== 'all') {
      const allSites = [...sitesDev, ...sitesProd]
      optionsToShow = tags?.siteId.filter(id => {
        const siteDetails = allSites.find(site => site.id === id)
        return siteDetails.organizationId === state.filterByOrg
      })
    }
    setSitesOptions(optionsToShow)
  }, [
    tags?.siteId?.length,
    tags?.siteId,
    state.filterByOrg,
    sitesDev,
    sitesProd
  ])

  useEffect(() => {
    const validateDates = async () => {
      try {
        const dateObj = { start: debouncedStartDate, end: debouncedEndDate }
        await dateRangeSchema.validate(dateObj, { abortEarly: true })
        if (state.dateError)
          dispatch({ type: 'clearError', field: 'dateError' })
      } catch (err) {
        dispatch({ type: 'update', field: 'dateError', value: err.message })
      }
    }

    validateDates()
  }, [debouncedStartDate, debouncedEndDate])

  useEffect(() => {
    if (keys.length > 0) {
      dispatch({ type: 'update', field: 'filteredKeys', value: keys })
    }
  }, [keys, view])

  useEffect(() => {
    if (costAndUsage?.length > 0) {
      const groupKeys = costAndUsage?.map(({ Groups }) => {
        return Groups.map(({ Services }) => {
          let servicesIndex = 0
          if (Services?.length > 1) {
            const envIndex = Services?.findIndex(item =>
              item.includes('environment$')
            )
            if (envIndex === 0) servicesIndex = 1
          }
          return cleanService(Services[servicesIndex])
        })
      })

      const nextKeys = [...new Set(groupKeys.flat())]
      nextKeys.sort().reverse()
      setKeys(nextKeys)
      let groupedData = []

      if (state.isIndexedByTime) {
        groupedData = costAndUsage?.map(({ TimePeriod, Groups }) => {
          const data = Groups.reduce((acc, item) => {
            const { Services, Amount } = item
            let servicesIndex = 0
            if (Services?.length > 1) {
              const envIndex = Services?.findIndex(item =>
                item.includes('environment$')
              )
              if (envIndex === 0) servicesIndex = 1
            }
            const serviceName = cleanService(Services[servicesIndex])
            let newValue = parseFloat(Amount)
            if (acc.hasOwnProperty(serviceName)) {
              newValue += parseFloat(acc[serviceName])
            }
            acc[serviceName] = newValue.toFixed(2)
            return acc
          }, {})

          return {
            timePeriod: TimePeriod.Start,
            ...data
          }
        })
      } else if (!state.isIndexedByTime) {
        const envGroupedData = costAndUsage?.reduce((acc, { Groups }) => {
          const data = Groups.reduce((acc, item) => {
            const { Services, Amount } = item
            let servicesIndex = 0
            if (Services?.length > 0) {
              const envIndex = Services?.findIndex(item =>
                item.includes('environment$')
              )
              if (envIndex === 0) servicesIndex = 1
              const environment = cleanService(Services[envIndex])
              if (!acc.hasOwnProperty(environment))
                acc = { ...acc, [environment]: { environment } }
              acc[environment][cleanService(Services[servicesIndex])] =
                parseFloat(Amount).toFixed(2)
            }
            return acc
          }, {})

          const newArr = { ...acc }
          const envKeys = Object.keys(newArr)
          if (Object.keys(acc).length > 0) {
            envKeys.forEach(envName => {
              Object.keys(data[envName]).forEach(key => {
                if (key !== 'environment') {
                  const runningTotal =
                    parseFloat(data[envName][key] || 0) +
                    parseFloat(newArr[envName][key] || 0)
                  newArr[envName][key] = runningTotal.toFixed(2)
                }
              })
            })
          } else {
            return data
          }

          return newArr
        }, {})

        groupedData = Object.keys(envGroupedData).map(key => {
          return envGroupedData[key]
        })
      }

      setData(groupedData)
    }
  }, [costAndUsage, envs, view, state.isIndexedByTime])

  const onClickView = e => {
    e.preventDefault()
    if (e.target.value === 'environments' && !state.isIndexedByTime) {
      dispatch({ type: 'update', field: 'isIndexedByTime', value: true })
    }
    setView(e.target.value)
  }

  const onClickEnv = e => {
    e.preventDefault()
    const clickedEnv = e.target.value
    const index = envs.indexOf(clickedEnv)
    let newEnvs = [...envs]
    if (index > -1) {
      newEnvs.splice(index, 1)
    } else {
      newEnvs.push(clickedEnv)
    }
    setEnvs(newEnvs)
  }

  const onChooseDate = e => {
    e.preventDefault()
    dispatch({ value: e.target.value, field: e.target.name, type: 'update' })
  }

  const onClickToggleGroupMode = () => {
    dispatch({ type: 'update', field: 'isStacked', value: !state.isStacked })
  }

  const onClickToggleIndexBy = () => {
    dispatch({
      type: 'update',
      field: 'isIndexedByTime',
      value: !state.isIndexedByTime
    })
  }

  const onClickFilter = () => {
    setShowFilterOptions(!showFilterOptions)
  }

  const onSelectFilterOption = e => {
    dispatch({
      type: e.target.checked ? 'add' : 'remove',
      field: 'filteredKeys',
      value: e.target.name
    })
  }

  const onSelectOrg = ({ target }) => {
    dispatch({ type: 'update', field: 'filterByOrg', value: target.value })
    dispatch({ type: 'update', field: 'filterBySite', value: 'all' })
    if (target.value !== 'all' && view !== 'resources') {
      setView('resources')
    }

    if (target.value === 'all' && view !== 'services') {
      setView('services')
    }
  }

  const onSelectSite = ({ target }) => {
    dispatch({ type: 'update', field: 'filterBySite', value: target.value })
    if (target.value !== 'all' && view !== 'resources') {
      setView('resources')
    }

    if (target.value === 'all' && view !== 'services') {
      setView('services')
    }
  }

  const getSiteName = siteId => {
    const site = [...sitesDev, ...sitesProd].find(({ id }) => id === siteId)
    if (site) return site.name
    return siteId
  }

  const getOrgsOptions = type => {
    const orgsFromTags = tags.organizationId
    const orgsDetails = type === 'dev' ? devOrganizations : prodOrganizations
    const options = orgsDetails.filter(org => orgsFromTags.includes(org.id))

    if (options.length === 0) return null

    const headerOption = (
      <option key={type} disabled>
        {type === 'dev' ? 'Development' : 'Production'}
      </option>
    )

    const listOfOptions = options.map(({ name, id }) => (
      <option key={id} value={id}>
        {name}
      </option>
    ))

    return [headerOption, ...listOfOptions]
  }

  const envsFormatted = envsFetched.reduce((acc, env, index) => {
    let appendString = env
    const isFirst = index === 0
    if (!isFirst) {
      appendString = index < envsFetched?.length - 1 ? `, ${env}` : ` & ${env}`
    }
    return `${acc}${appendString}`
  }, '')
  const envsText = `(${envsFormatted})`

  return (
    <div>
      <div>
        <h1 className='font-black text-2xl'>{I18n.get('Costs')}</h1>
        <p className='py-2'>{I18n.get('View Coretex cost data.')}</p>
      </div>
      <p className='mt-6 mb-2 text-sm text-slate-300'>Configure chart data</p>
      <div className='flex flex-wrap gap-x-7 gap-y-3 border-2 p-4 border-slate-100/50 rounded drop-shadow-md bg-white'>
        <div>
          <p className='text-sm mb-2'>Filter by organization:</p>
          <select
            className='p-1 mr-2 border-2 border-blue-100 rounded text-blue-500 text-sm h-10 w-40'
            onInput={onSelectOrg}
            value={state.filterByOrg}
            disabled={tags?.organizationId?.length === 0}
          >
            <option value='all'>All organizations</option>
            {getOrgsOptions('dev')}
            {getOrgsOptions('prod')}
          </select>
        </div>
        <div>
          <p className='text-sm mb-2'>Filter by site:</p>
          <select
            className='p-1 mr-2 border-2 border-blue-100 rounded text-blue-500 text-sm h-10 w-40'
            onInput={onSelectSite}
            value={state.filterBySite}
            disabled={tags?.siteId?.length === 0}
          >
            <option value='all'>All sites</option>
            {sitesOptions?.map(siteId => (
              <option key={siteId} value={siteId}>
                {getSiteName(siteId)}
              </option>
            ))}
          </select>
        </div>
        {state.filterBySite === 'all' && state.filterByOrg === 'all' && (
          <Fragment>
            <div>
              <p className='text-sm mb-2'>Group vertical axis by:</p>
              <div>
                {Object.keys(groupByOptions).map((key, index) => (
                  <button
                    className={getToggleClass(
                      Object.keys(groupByOptions),
                      index,
                      view
                    )}
                    onClick={onClickView}
                    value={key}
                    key={key}
                  >
                    {viewStrings[key]}
                  </button>
                ))}
              </div>
            </div>
            <div>
              <p className='text-sm mb-2'>View data for environment:</p>
              {envsDefault.map(envItem => (
                <button
                  className={
                    envs.includes(envItem) ? tagSelected : tagUnselected
                  }
                  onClick={onClickEnv}
                  value={envItem}
                  key={envItem}
                >
                  {envStrings[envItem]}
                </button>
              ))}
            </div>
          </Fragment>
        )}
        <div>
          <div>
            <p className='text-sm mb-2'>Date range:</p>
            <div>
              <input
                type='date'
                name='startDate'
                className='p-1 mx-2 first:ml-0 border-2 border-blue-100 rounded text-blue-500 text-sm h-10'
                onChange={onChooseDate}
                value={state?.startDate}
                max={getMaxStartDate()}
                min={getMinStartDate()}
              />
              &mdash;
              <input
                type='date'
                name='endDate'
                className='p-1 mx-2 first:ml-0 border-2 border-blue-100 rounded text-blue-500 text-sm h-10'
                onChange={onChooseDate}
                value={state?.endDate}
                min={getMinEndDate(state.startDate)}
                max={getMaxEndDate(state.startDate)}
              />
            </div>
            {state.dateError && (
              <span className='text-orange-400 text-xs'>{state.dateError}</span>
            )}
          </div>
        </div>
        <div className='self-end grow flex'>
          <button
            className='disabled:cursor-not-allowed bg-blue-500 text-white p-2 px-6 h-12 rounded w-36 hover:bg-blue-100 hover:text-black text-sm ml-auto'
            onClick={fetchLatestConfigData}
            disabled={state.dateError}
            //TODO: disable if data has not changed
          >
            Apply
          </button>
        </div>
      </div>
      {!globalIsLoading && isLoading && (
        <div className='m-32 overflow-hidden'>
          <Loader fullpage={false} text={`${I18n.get('Loading...')}`} />
        </div>
      )}
      {!isLoading &&
        (keys?.length < 1 || data?.length < 1) &&
        I18n.get('No data to show')}
      {!isLoading && (
        <Fragment>
          <div className='flex justify-between items-end'>
            <p className='mt-20 text-xl font-semibold'>
              {`${I18n.get('Costs of')} ${state.appliedView} by ${
                state.isIndexedByTime
                  ? `daily expenditure ${envsText}`
                  : 'environment'
              }`}
            </p>
            <div className='flex items-end relative'>
              {state.appliedView !== 'environments' && (
                <CalendarDaysIcon
                  className={
                    state.isIndexedByTime
                      ? filterIconSelectedClass
                      : filterIconUnselectedClass
                  }
                  onClick={onClickToggleIndexBy}
                />
              )}
              <ChartBarIcon
                className={
                  !state.isStacked
                    ? filterIconSelectedClass
                    : filterIconUnselectedClass
                }
                onClick={onClickToggleGroupMode}
              />
              <FunnelIcon
                className={
                  state.filteredKeys?.length < keys.length
                    ? filterIconSelectedClass
                    : filterIconUnselectedClass
                }
                onClick={onClickFilter}
              />
              {showFilterOptions && (
                <ul className='absolute top-16 right-0 bg-white p-4 z-10 whitespace-nowrap drop-shadow-md'>
                  {[...keys].sort().map(key => (
                    <li key={key}>
                      <input
                        type='checkbox'
                        name={key}
                        onChange={onSelectFilterOption}
                        checked={state.filteredKeys.includes(key)}
                      />
                      <span className='mx-2 text-nowrap'>{key}</span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          {costAndUsage.length > 0 && (
            <div className='grid grid-cols-3 border-slate-200 bg-slate-100/25 p-2 gap-x-5 mt-8'>
              <p className={gridCellStyle}>Date range</p>
              <p className={gridCellStyle}>Total cost (USD)</p>
              <p className={gridCellStyle}>Average daily cost (USD)</p>
              <p className={gridCellStyle}>
                <strong>{getDateRange(costAndUsage)}</strong>
              </p>
              <p className={gridCellStyle}>
                <strong>{`$${getTotalAmount(data).toFixed(2)}`}</strong>
              </p>
              <p className={gridCellStyle}>
                <strong>{`$${getAverageAmount(data, costAndUsage).toFixed(
                  2
                )}`}</strong>
              </p>
            </div>
          )}
          <Chart
            keys={
              state.isStacked
                ? state.filteredKeys
                : [...state.filteredKeys].sort()
            }
            data={data}
            customTooltip={data => (
              <Tooltip data={data} keys={[...state.filteredKeys]} />
            )}
            groupMode={state.isStacked ? 'stacked' : 'grouped'}
            indexBy={state.isIndexedByTime ? 'timePeriod' : 'environment'}
          />
        </Fragment>
      )}
    </div>
  )
}

export default Costs
