import { createElement, Fragment, useEffect, useState } from 'react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import { I18n } from 'aws-amplify'

import Auth from './Auth'
import Banner from '../Shared/Banner'

import { ReactComponent as Logo } from '../../assets/logo.svg'
import { ReactComponent as Image } from '../../assets/image-login-teal.svg'

const Wrapper = () => {
  const [authState, setAuthState] = useState('signin')

  useEffect(() => {
    return onAuthUIStateChange(nextAuthState => {
      setAuthState(nextAuthState)
    })
  }, [])

  if (authState === AuthState.SignedIn) {
    return null
  }

  return (
    <Fragment>
      <Banner />
      <div className='grid grid-cols-2 w-full h-screen'>
        <div className='m-auto flex flex-col justify-center items-center pb-10'>
          <div className='w-full h-36 mb-24 text-center'>
            <Logo className='h-full w-32 m-auto' />
            <h2 className='text-2xl pb-2 font-black m-4 mt-14'>
              {I18n.get('Sign in to Control Tower')}
            </h2>
          </div>
          {createElement(Auth)}
        </div>
        <div className='bg-slate-100/50'>
          <Image className='w-full h-full p-16 max-w-[55rem] mx-auto drop-shadow-2xl' />
        </div>
      </div>
    </Fragment>
  )
}

export default Wrapper
