import { useDispatch } from 'react-redux'
import { useEffect, useState, useRef, useCallback } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { I18n } from 'aws-amplify'

import { useAuth } from './contexts/auth-context'
import { sendFetchInitTagValues } from './slices/resource_groups_tagging'
import { setNewZenhubReportGenerated } from './slices/zenhub_reports'
import { setSuccess } from './actions/util'
import { getIdToken } from './api/utils'
import { getGlobalIsLoading } from './reducers/selectors'

import withAuthenticator from './components/Auth/WithAuthenticator'
import Banner from './components/Shared/Banner'
import Layout from './components/Shared/Layout'
import Loader from './components/Shared/Loader'
import Routes from './routes'

function App() {
  const dispatch = useDispatch()
  const isLoading = getGlobalIsLoading()
  const [idToken, setIdToken] = useState(null)
  const interval = useRef(null)
  const auth = useAuth()

  const API_ENDPOINT = process.env.REACT_APP_ENDPOINT
  const wsApiEndpoint = API_ENDPOINT.includes('localhost')
    ? 'https://api.coretex.ag/control-tower'
    : API_ENDPOINT

  async function retrieveIdToken() {
    const idToken = await getIdToken()
    setIdToken(idToken)
  }

  const getWebSocketUrl = useCallback(() => {
    return new Promise(resolve => {
      if (idToken && auth?.cognitoUser?.attributes) {
        const sub = auth?.cognitoUser?.attributes?.sub
        const baseUrl = `wss://ws-${wsApiEndpoint.replace('https://', '')}`
        const authUri = `authorization=${idToken}`
        const userId = `userId=${sub}`
        const websocketUrl = `${baseUrl}?${authUri}&${userId}`
        resolve(websocketUrl)
      }
    })
  }, [idToken, auth?.cognitoUser?.attributes])

  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(
    getWebSocketUrl,
    {
      shouldReconnect: closeEvent => true
    }
  )

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      interval.current = setInterval(() => {
        sendJsonMessage({ action: '$default', message: 'ping' })
      }, 1000 * 60 * 5)
    }

    if (readyState === ReadyState.CLOSED) {
      clearInterval(interval.current)
      interval.current = null
    }
  }, [readyState, sendJsonMessage])

  useEffect(() => {
    if (lastMessage?.data) {
      const { data, type } = JSON.parse(lastMessage?.data)
      if (type === 'zenhub-report') {
        dispatch(setNewZenhubReportGenerated(data))
        dispatch(
          setSuccess(`${I18n.get('New report generated:')} ${data.sprintName}`)
        )
      }
    }
  }, [lastMessage, dispatch])

  useEffect(() => {
    if (auth?.cognitoUser?.username) {
      dispatch(sendFetchInitTagValues())
      retrieveIdToken()
    }
  }, [auth?.cognitoUser?.username, dispatch])

  return (
    <div>
      <Banner />
      {isLoading && <Loader />}
      <Layout>
        <Routes />
      </Layout>
    </div>
  )
}

export default withAuthenticator(App)
