import { Fragment, useState, useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid'

const listClass = 'ml-4 text-sm leading-10 text-slate-600'
const listItemClass = 'hover:bg-slate-600 hover:text-white w-full px-2 truncate'
const listItemSelectedClass =
  'bg-white w-full px-2 font-semibold hover:cursor-default truncate'
const iconClass =
  'w-6 p-1 m-2 hover:cursor-pointer hover:bg-blue-400 hover:text-white rounded-full'

const SidebarItem = ({ navDetails, expanded = true }) => {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()

  const { id, name, path, icon } = navDetails
  const navClass =
    location.pathname === path ? listItemSelectedClass : listItemClass

  const toggleSection = () => {
    setIsOpen(!isOpen)
  }

  const getChevronIcon = () => {
    if (isOpen) {
      return <ChevronUpIcon className={iconClass} onClick={toggleSection} />
    }

    return <ChevronDownIcon className={iconClass} onClick={toggleSection} />
  }

  useEffect(() => {
    if (!expanded) setIsOpen(false)
  }, [expanded])

  const listItemTextClass = `transition-opacity ease-in-out duration-300 ${
    expanded ? 'opacity-1' : 'opacity-0'
  }`

  return (
    <Fragment key={id}>
      {!navDetails?.children && (
        <div className={navClass}>
          <Link to={path}>
            <li className='truncate mr-2'>
              {icon}
              <span className={listItemTextClass}>{name}</span>
            </li>
          </Link>
        </div>
      )}
      {navDetails?.children && (
        <Fragment>
          <div className={`flex justify-between ${navClass}`}>
            <Link to={path} className='grow'>
              <li className='truncate mr-2'>
                {icon}
                <span className={listItemTextClass}>{name}</span>
              </li>
            </Link>
            {getChevronIcon(path)}
          </div>
          {isOpen && (
            <ul className={`${listClass} ml-8`}>
              {navDetails.children?.map(child => (
                <SidebarItem navDetails={child} key={child?.id} />
              ))}
            </ul>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

export default SidebarItem
