import { createReducer } from '../../utils/ReduxUtils'

import {
  SET_ERRORS,
  CLEAR_ERRORS,
  SET_SUCCESS,
  CLEAR_SUCCESS,
  SET_GLOBAL_LOADING
} from '../../actions/util'

const initialState = {
  errors: [],
  success: '',
  globalIsLoading: false
}
const handler = {
  [SET_ERRORS]: (state, action) => ({
    ...state,
    errors:
      typeof action.errorMessages === 'string'
        ? [action.errorMessages]
        : action.errorMessages
  }),
  [CLEAR_ERRORS]: state => ({
    ...state,
    errors: []
  }),
  [SET_SUCCESS]: (state, action) => ({
    ...state,
    errors: [],
    success: action.successMessage
  }),
  [CLEAR_SUCCESS]: state => ({
    ...state,
    success: ''
  }),
  [SET_GLOBAL_LOADING]: (state, action) => ({
    ...state,
    globalIsLoading: action?.isLoading
  })
}

export const utilReducer = createReducer(handler, initialState)
