import { useEffect, useRef, useState } from 'react'
import { I18n } from 'aws-amplify'

const Tooltip = ({ data, keys }) => {
  const { data: tooltipData, id, color } = data

  const tooltipRef = useRef(null)
  const [offset, setOffset] = useState({ top: 0 })

  const { timePeriod, environment, ...rest } = tooltipData

  const insertRow = (key, value, extraClasses = '') => {
    let rowClassStyle = `flex justify-between ${extraClasses}`
    let rowStyle = {}

    if (key === id) {
      const newClass = `${rowClassStyle} font-bold`
      rowClassStyle = newClass
      rowStyle = { color }
    }
    return (
      <div className={rowClassStyle} style={rowStyle} key={key}>
        <p>{key}</p>
        <p className='w-14 text-left'>{value ? `$${value}` : '-'}</p>
      </div>
    )
  }

  const dailyTotal = keys
    .reduce((acc, key) => acc + parseFloat(rest[key] || 0), 0)
    .toFixed(2)

  const dailyTotalRounded = parseFloat(
    Math.round(dailyTotal * 100) / 100
  ).toFixed(2)

  useEffect(() => {
    const tooltipRect = tooltipRef.current.getBoundingClientRect()
    const viewHeight = window.innerHeight
    const { height: tooltipHeight, top, bottom } = tooltipRect
    if (viewHeight < bottom && top > tooltipHeight) setOffset({ bottom: 0 })
    if (top < 0) setOffset({ top: 0 })
  }, [data])

  const insertHeader = () => {
    const dailyCostsText = `${I18n.get('Daily costs for')} ${timePeriod}`
    const costsByEnvText = `${I18n.get('Costs for')} ${environment}`
    return (
      <p className='text-white mb-4 font-bold text-base'>
        {timePeriod ? dailyCostsText : costsByEnvText}
      </p>
    )
  }

  return (
    <div
      style={offset}
      className='mx-6 absolute p-4 bg-slate-800 text-slate-100 font-light text-sm w-96'
      ref={tooltipRef}
    >
      {insertHeader()}
      {keys.sort().map(key => {
        return insertRow(key, tooltipData[key])
      })}
      <hr className='mt-4 mb-2' />
      {insertRow(
        I18n.get('Total cost for selected fields'),
        dailyTotalRounded,
        'text-white font-extrabold'
      )}
    </div>
  )
}

export default Tooltip
