import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'aws-amplify'

import Table from './Table'
import Loader from '../Shared/Loader'

import {
  getCognitoMissingMigrationUsers,
  getIsLoadingCognitoMissingMigrationUsers,
  getRequestedMissingMigrationUsers
} from '../../reducers/selectors'
import { sendFetchMissingMigratedUsers } from '../../slices/cognito'

const MigrationUsers = () => {
  const [showDev, setShowDev] = useState(true)
  const dispatch = useDispatch()
  const users = getCognitoMissingMigrationUsers()
  const loading = getIsLoadingCognitoMissingMigrationUsers()
  const requested = getRequestedMissingMigrationUsers()

  if (!requested) {
    dispatch(sendFetchMissingMigratedUsers())
  }

  function onClickEnv(e) {
    e.preventDefault()
    setShowDev(!showDev)
  }

  return (
    <div>
      <div className='flex justify-between items-center'>
        <div>
          <h1 className='font-black text-2xl'>{I18n.get('Migration users')}</h1>
          <p className='py-2'>
            {I18n.get(
              'The following users have not migrated their account to the new Cognito Pool.'
            )}
          </p>
        </div>
        <button
          onClick={onClickEnv}
          className='text-white text-sm bg-slate-600 hover:bg-slate-100 rounded p-2 hover:text-slate-600 h-9'
        >
          {I18n.get('See')} {`${showDev ? 'prod' : 'dev'}`} {I18n.get('users')}
        </button>
      </div>
      {loading && <Loader fullpage={false} text='Loading users' />}
      {!loading && (
        <div className='flex flex-wrap justify-start pt-8 gap-4'>
          <Table
            users={showDev ? users?.Dev ?? [] : users?.Prod ?? []}
            env={showDev ? 'Dev' : 'Prod'}
          />
        </div>
      )}
    </div>
  )
}

export default MigrationUsers
