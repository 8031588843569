import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getOrganizations } from '../../api/organizations'

export const fetchOrganizations = createAsyncThunk(
  'fetchOrganizations',
  async params => {
    const devResponse = await getOrganizations({ isDev: true })
    const prodResponse = await getOrganizations({ isDev: false })

    const error = devResponse?.error ?? prodResponse?.error
    return {
      organizationsDev: devResponse?.organizations,
      organizationsProd: prodResponse?.organizations,
      error
    }
  }
)

const managementOrganizationSlice = createSlice({
  name: 'managementOrganizationReducer',
  initialState: {
    organizationsDev: [],
    organizationsProd: [],
    error: null
  },
  extraReducers: builder => {
    builder
      .addCase(fetchOrganizations.pending, (state, action) => {
        state.error = null
      })
      .addCase(fetchOrganizations.fulfilled, (state, action) => {
        const { organizationsDev, organizationsProd } = action?.payload ?? {}
        state.error = action?.payload?.error ?? null
        state.organizationsDev = organizationsDev ?? []
        state.organizationsProd = organizationsProd ?? []
      })
  }
})

export default managementOrganizationSlice.reducer
