import { Fragment, useEffect, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { Hub, I18n } from 'aws-amplify'
import { ArrowLongLeftIcon } from '@heroicons/react/24/solid'

import { useAuth } from '../../contexts/auth-context'
import useAuthChannel from '../../hooks/useAuthChannel'
import { getFormErrors } from '../../utils'
import { setErrors, clearErrors } from '../../actions/util'

import Loader from '../Shared/Loader'

import { signInSchema, sendResetLinkSchema } from './validations'

const initalState = {
  password: '',
  username: '',
  loading: false,
  success: null,
  forgotPassword: false
}

const reducer = (state, action) => {
  if (action.type === 'update') {
    return { ...state, [action.id]: action.value }
  }
  return state
}

const inputDefaultStyle =
  'outline outline-1 outline-slate-100/60 p-4 rounded w-full bg-white h-12 text-slate-500 font-light mt-2 bg-slate-100/10'

function SignIn({ hubData }) {
  const auth = useAuth()
  const [authChannel, channelId] = useAuthChannel()
  const [state, dispatch] = useReducer(reducer, initalState)
  const reduxDispatch = useDispatch()

  useEffect(() => {
    return () => Hub.remove(channelId)
  }, [channelId])

  useEffect(() => {
    if (hubData.type === 'UserForgotPasswordSuccess') {
      setSuccess(
        I18n.get(
          'Password successfully changed. Sign in using your new password.'
        )
      )
    } else if (hubData.code === 'LimitExceededException') {
      reduxDispatch(setErrors(I18n.get('Too many tries. Try again later.')))
    }
  }, [hubData])

  useEffect(() => {
    switch (authChannel.message) {
      case 'error':
        if (authChannel.data.code === 'NotAuthorizedException') {
          reduxDispatch(setErrors(I18n.get('Incorrect username or password')))
        } else if (authChannel.data.code === 'LimitExceededException') {
          reduxDispatch(setErrors(I18n.get('Too many tries. Try again later.')))
        } else if (authChannel.data.code === 'UserDisabled') {
          reduxDispatch(
            setErrors(
              I18n.get(
                'Your user has been disabled. Please contact your administrator.'
              )
            )
          )
        }
        setLoading(false)
        break
      default:
        console.log(
          `HubListener ${channelId} - ${authChannel.message || 'no message'}`
        )
        break
    }
  }, [authChannel, channelId])

  const onSubmit = async e => {
    e.preventDefault()
    reduxDispatch(clearErrors())
    setLoading(true)
    if (state.forgotPassword) {
      await onForgotPassword()
    } else {
      await onSignIn()
    }
    setLoading(false)
  }

  const onForgotPassword = async () => {
    try {
      await sendResetLinkSchema.validate(state, { abortEarly: false })
      await auth.forgotPassword(state.username)
    } catch (error) {
      const bannerErrors = getFormErrors(error)
      reduxDispatch(setErrors(bannerErrors))
    }
  }

  const onSignIn = async () => {
    try {
      await signInSchema.validate(state, { abortEarly: false })
      await auth.signIn(state.username, state.password)
    } catch (error) {
      const bannerErrors = getFormErrors(error)
      reduxDispatch(setErrors(bannerErrors))
    }
  }

  const setSuccess = (success = {}) => {
    dispatch({ type: 'update', id: 'success', value: success })
  }

  const setLoading = loading => {
    dispatch({ type: 'update', id: 'loading', value: loading })
  }

  const onChangeInput = e => {
    const { id, value } = e.currentTarget
    dispatch({ type: 'update', id, value })
  }

  const goToForgotPassword = async e => {
    e.preventDefault()
    dispatch({ type: 'update', id: 'forgotPassword', value: true })
  }

  const goToSignIn = async e => {
    e.preventDefault()
    dispatch({ type: 'update', id: 'forgotPassword', value: false })
  }

  return (
    <Fragment>
      <div className='flex justify-center flex-col gap-2 items-center'>
        <h2 className='text-lg font-light mt-2'>
          {!state.forgotPassword
            ? 'Enter your details to proceed'
            : 'Reset your password'}
        </h2>
        <form onSubmit={onSubmit} className='flex flex-col items-start w-96'>
          <label className='mt-16'>{I18n.get('Email address')}</label>
          <input
            type='text'
            id='username'
            value={state.username}
            onChange={onChangeInput}
            className={inputDefaultStyle}
            placeholder={I18n.get('Enter your email addresss')}
          />
          {!state.forgotPassword && (
            <Fragment>
              <label className='mt-16'>{I18n.get('Password')}</label>
              <input
                type='password'
                id='password'
                value={state.password}
                onChange={onChangeInput}
                className={inputDefaultStyle}
                placeholder={I18n.get('Enter your email password')}
              />
            </Fragment>
          )}
          <button
            type='submit'
            className='p-2 mt-16 mb-8 bg-slate-500 text-white rounded w-full mx-auto hover:bg-slate-100 hover:text-slate-800 drop-shadow-[0_12px_18px_rgba(73,93,143,0.3)]'
            disabled={state.loading}
          >
            {!state.forgotPassword
              ? I18n.get('Sign in')
              : I18n.get('Send reset link')}
          </button>
        </form>
        {!state.forgotPassword && (
          <button onClick={goToForgotPassword} className='text-sm'>
            {I18n.get('Forgot password? Click here.')}
          </button>
        )}
        {state.forgotPassword && (
          <button onClick={goToSignIn} className='text-sm'>
            <div className='flex items-center justify-center gap-4'>
              <ArrowLongLeftIcon className='h-5 w-5 mx-auto' />
              <p>{I18n.get('Back to sign in')}</p>
            </div>
          </button>
        )}
      </div>
      {state.loading && (
        <Loader
          text={
            !state.forgotPassword
              ? I18n.get('Signing in')
              : I18n.get('Sending email')
          }
        />
      )}
    </Fragment>
  )
}

export default SignIn
