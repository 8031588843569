import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { XMarkIcon } from '@heroicons/react/24/solid'
import {
  getBannerErrors,
  getBannerErrorNew,
  getBannerSuccessMsg,
  getBannerSuccessNew
} from '../../../reducers/selectors'
import { clearErrors, clearSuccess } from '../../../actions/util'
import { cleanBanner } from '../../../slices/utils'

const errorBanner = 'bg-orange-400'
const successBanner = 'bg-green-500'

const Banner = () => {
  const dispatch = useDispatch()
  const errors = getBannerErrors()
  const errorNew = getBannerErrorNew()
  const successMsg = getBannerSuccessMsg()
  const successMsgNew = getBannerSuccessNew()
  const hasErrors = errors.length > 0 || errorNew
  const hasSuccessMsg = successMsg?.length > 0 || successMsgNew

  useEffect(() => {
    if (hasSuccessMsg) {
      setTimeout(() => {
        dispatch(clearSuccess())
        if (successMsgNew) dispatch(cleanBanner())
      }, '5000')
    }
  }, [successMsg, successMsgNew])

  if (!hasErrors && !hasSuccessMsg) return null

  const onClickClose = () => {
    if (hasErrors) dispatch(clearErrors())
    if (hasSuccessMsg) dispatch(clearSuccess())
    if (successMsgNew || errorNew) dispatch(cleanBanner())
  }

  const errorsToUse = errors?.length > 0 ? errors : [errorNew]

  return (
    <div
      className={`absolute top-0 right-0 z-30 w-full p-4 flex justify-between items-start ${
        hasErrors ? errorBanner : successBanner
      }`}
    >
      <div>
        {hasErrors &&
          Object.keys(errorsToUse).map(key => (
            <div className='w-full block' key={key}>
              <p>{errorsToUse[key]}</p>
            </div>
          ))}
        {hasSuccessMsg && (
          <div className='w-full block'>
            <p>{successMsg?.length > 0 ? successMsg : successMsgNew}</p>
          </div>
        )}
      </div>
      <button onClick={onClickClose}>
        <XMarkIcon className='h-6 w-6' />
      </button>
    </div>
  )
}

export default Banner
