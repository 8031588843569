import { API, graphqlOperation } from 'aws-amplify'

import { API_NAME, GRAPHQL_ROUTE } from '../config'
import { getIdToken } from '../utils'

export async function getOrganizations(params) {
  try {
    const query = /* GraphQL */ `
      query getOrganizations($isDev: Boolean!) {
        getOrganizations(isDev: $isDev) {
          id
          name
        }
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(query, params)
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return {
      organizations: response.data.getOrganizations,
      error: null
    }
  } catch (err) {
    return { organizations: null, error: err.message }
  }
}
