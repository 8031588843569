import { I18n } from 'aws-amplify'
import { useAuth } from '../../contexts/auth-context'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import Loader from '../Shared/Loader'

import { setErrors, clearErrors } from '../../actions/util'
import { getFormErrors } from '../../utils'
import { codeSchema } from './validations'

function VerifyAttribute({ title, attribute, attributeValue }) {
  const [code, setCode] = useState('')
  const [loading, setIsLoading] = useState(false)
  const reduxDispatch = useDispatch()

  const auth = useAuth()
  const verifyAttribute = async e => {
    try {
      e.preventDefault()
      reduxDispatch(clearErrors())
      await codeSchema.validate({ code }, { abortEarly: false })
      setIsLoading(true)
      try {
        await auth.verifyCurrentUserAttributeSubmit(attribute, code)
      } finally {
        setIsLoading(false)
      }
    } catch (err) {
      const bannerErrors = getFormErrors(err)
      reduxDispatch(setErrors(bannerErrors))
    }
  }

  const onChangeInput = e => {
    setCode(e.currentTarget.value)
  }

  return (
    <div className='text-center'>
      <h2 className='text-lg mt-2 mb-2'>
        {I18n.get('Verify your email address')}
      </h2>
      <p>{`${I18n.get('A code was sent to')} ${
        auth?.cognitoUser?.attributes?.email ||
        attributeValue | I18n.get('your email')
      }`}</p>
      <form
        onSubmit={verifyAttribute}
        className='flex flex-col gap-2 items-start text-center'
      >
        <label className='mt-16'>{I18n.get('Code')}</label>
        <input
          type='password'
          id='password'
          value={code}
          onChange={onChangeInput}
          className='outline outline-1 outline-slate-100/60 p-4 rounded w-full bg-white h-12 text-slate-500 font-light mt-2 bg-slate-100/10'
          placeholder={I18n.get('Enter code sent to your email address')}
        />
        <button
          type='submit'
          className='p-2 mt-16 mb-8 bg-slate-500 text-white rounded w-full mx-auto hover:bg-slate-100 hover:text-slate-800 drop-shadow-[0_12px_18px_rgba(73,93,143,0.3)]'
        >
          {`${I18n.get('Verify')} ${title}`}
        </button>
      </form>
      {loading && <Loader text={I18n.get('Verifying')} />}
    </div>
  )
}

export default VerifyAttribute
