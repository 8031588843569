const TranslationFile = ({ name, file, url, showCsvContent }) => {
  const fileName = file.split(`${name}-`)[1]

  function showContent() {
    showCsvContent(fileName, url)
  }

  return (
    <div className='px-2 py-4 border-t'>
      <p className='mb-2'>{fileName}</p>
      <div className='flex justify-between'>
        <a
          href={url}
          download
          className='text-sm rounded px-4 py-2 bg-blue-500 text-white hover:bg-blue-700'
        >
          Download
        </a>
        <button
          className='text-sm rounded px-4 py-2 bg-blue-500 text-white hover:bg-blue-700'
          onClick={showContent}
        >
          View
        </button>
      </div>
    </div>
  )
}

const TranslationCard = ({ name, items, showCsvContent }) => {
  return (
    <div className='bg-slate-100/50 rounded p-2 mr-2'>
      <div className='mt-2'>
        <p className='justify-self-center text-center m-auto font-extrabold text-lg'>
          {name}
        </p>
      </div>
      <div>
        <div className='pt-2 pb-4 flex flex-col justify-between'>
          {items.map(({ file, url }) => {
            return (
              <TranslationFile
                key={file}
                name={name}
                file={file}
                url={url}
                showCsvContent={showCsvContent}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default TranslationCard
