import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { syncHelpText, syncReleaseNotes, syncTags } from '../../api/fyi'

export const sendSyncHelpText = createAsyncThunk(
  'sendSyncHelpText',
  async params => {
    const { message, error } = await syncHelpText(params)

    return {
      message,
      error: error ?? null
    }
  }
)

export const sendSyncReleaseNotes = createAsyncThunk(
  'sendSyncReleaseNotes',
  async params => {
    const { message, error } = await syncReleaseNotes(params)

    return {
      message,
      error: error ?? null
    }
  }
)

export const sendSyncTags = createAsyncThunk('sendSyncTags', async params => {
  const { message, error } = await syncTags(params)

  return {
    message,
    error: error ?? null
  }
})

const fyiSlice = createSlice({
  name: 'fyiReducer',
  initialState: {
    isSyncing: false,
    message: null
  },
  extraReducers: builder => {
    builder.addCase(sendSyncHelpText.pending, (state, action) => {
      state.isSyncing = true
    })
    builder.addCase(sendSyncHelpText.fulfilled, (state, action) => {
      state.isSyncing = false
      state.message = action.payload.message
    })
    builder.addCase(sendSyncReleaseNotes.pending, (state, action) => {
      state.isSyncing = true
    })
    builder.addCase(sendSyncReleaseNotes.fulfilled, (state, action) => {
      state.isSyncing = false
      state.message = action.payload.message
    })
    builder.addCase(sendSyncTags.pending, (state, action) => {
      state.isSyncing = true
    })
    builder.addCase(sendSyncTags.fulfilled, (state, action) => {
      state.isSyncing = false
      state.message = action.payload.message
    })
  }
})

const { reducer } = fyiSlice

export default reducer
