import { useState, useEffect } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'

import Row from './Row'
import Loader from '../Shared/Loader'

import { columnHeaders } from './utils'
import { requestTimestreamCosts } from '../../slices/timestream_costs'
import { fetchOrganizations } from '../../slices/management/organizations'
import { fetchSites } from '../../slices/management/sites'

import {
  getTimestreamCosts,
  getTimestreamLoading,
  getDevOrganizations,
  getProdOrganizations,
  getSitesDev,
  getSitesProd
} from '../../reducers/selectors'

const columnHeaderClass =
  'border-b-2 border-slate-200 text-slate-600 text-sm py-2 pr-2'

const ENVS = ['dev', 'prod']

const getOrgsWithSites = (orgs, sites) => {
  const siteOrgIds = sites.map(({ organizationId }) => organizationId)
  return orgs.filter(({ id }) => siteOrgIds.includes(id))
}

const TimestreamCosts = () => {
  const timestreamCosts = getTimestreamCosts()
  const devOrganizations = getDevOrganizations()
  const prodOrganizations = getProdOrganizations()
  const loading = getTimestreamLoading()
  const sitesDev = getSitesDev()
  const sitesProd = getSitesProd()

  const [selectedEnv, setSelectedEnv] = useState('dev')
  const [selectedOrg, setSelectedOrg] = useState('')
  const [orgOptions, setOrgOptions] = useState([])

  const dispatch = useDispatch()

  const noSites = sitesDev?.length === 0 && sitesProd?.length === 0
  const noOrgs =
    devOrganizations?.length === 0 && prodOrganizations?.length === 0

  useEffect(() => {
    if (noSites) dispatch(fetchSites())
  }, [noSites, dispatch])

  useEffect(() => {
    if (noOrgs) dispatch(fetchOrganizations())
  }, [noOrgs, dispatch])

  useEffect(() => {
    const organizations =
      selectedEnv === 'dev'
        ? getOrgsWithSites(devOrganizations, sitesDev)
        : getOrgsWithSites(prodOrganizations, sitesProd)

    setOrgOptions(organizations)
    setSelectedOrg(organizations[0]?.id)
  }, [
    selectedEnv,
    dispatch,
    devOrganizations,
    prodOrganizations,
    sitesDev,
    sitesProd
  ])

  function onClickFetch() {
    const isDev = selectedEnv === 'dev'
    dispatch(
      requestTimestreamCosts({
        organizationId: selectedOrg,
        isDev
      })
    )
  }

  function onSelectEnv(e) {
    setSelectedEnv(e.target.value)
  }

  function onSelectOrg(e) {
    setSelectedOrg(e.target.value)
  }

  const resultsFetched = timestreamCosts[selectedEnv][selectedOrg]

  return (
    <div>
      <h1 className='font-black text-2xl'>{I18n.get('Timestream costs')}</h1>
      <p className='py-2'>
        {I18n.get(
          "View Coretex's last three months of Timestream costs by organization."
        )}
      </p>
      <div className='flex'>
        <div>
          <p className='mt-5 text-slate-400 mr-2 text-sm'>
            Select an environment
          </p>
          <select
            onChange={onSelectEnv}
            value={selectedEnv}
            className='bg-slate-100/50 p-2 h-10 rounded mt-2 mr-5 w-44'
            name='version'
            id='version'
          >
            {ENVS?.map(env => (
              <option key={env} value={env}>
                {env}
              </option>
            ))}
          </select>
        </div>
        <div>
          <p className='mt-5 text-slate-400 mr-2 text-sm'>
            Select an organization
          </p>
          <select
            onChange={onSelectOrg}
            value={selectedOrg}
            className='bg-slate-100/50 p-2 h-10 rounded mt-2 mr-5 w-44'
            name='version'
            id='version'
          >
            {orgOptions?.map(org => (
              <option key={org.id} value={org.id}>
                {org.name}
              </option>
            ))}
          </select>
        </div>
        <div className='self-end grow flex'>
          <button
            className='disabled:cursor-not-allowed bg-blue-500 text-white p-2 px-6 h-12 rounded w-36 hover:bg-blue-100 hover:text-black text-sm ml-auto'
            onClick={onClickFetch}
            disabled={selectedOrg?.length === 0}
          >
            Fetch
          </button>
        </div>
      </div>
      {loading && <Loader fullpage={false} text='Loading timestream costs' />}
      {!loading && resultsFetched && resultsFetched?.length === 0 && (
        <p style={{ marginTop: '2rem' }}>No costs to display.</p>
      )}
      {!loading && !resultsFetched && (
        <p style={{ marginTop: '2rem' }}>
          Click 'Fetch' to get available data.
        </p>
      )}
      {!loading && resultsFetched?.length > 0 && (
        <table className='table-auto w-full border-collapse mt-8'>
          <thead>
            <tr>
              {Object.keys(columnHeaders).map(key => (
                <td key={`column-${key}`} className={columnHeaderClass}>
                  {columnHeaders[key]}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {selectedEnv &&
              selectedOrg?.length &&
              timestreamCosts[selectedEnv][selectedOrg] &&
              timestreamCosts[selectedEnv][selectedOrg].map((row, index) => {
                return (
                  <Row
                    key={`${selectedEnv}-${selectedOrg}-${index}}`}
                    data={row}
                  />
                )
              })}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default TimestreamCosts
