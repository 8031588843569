import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  syncFeatureFlags,
  createFeatureFlag,
  getFeatureFlags,
  updateFeatureFlag
} from '../../api/feature_flags'

export const sendSyncFeatureFlags = createAsyncThunk(
  'sendSyncFeatureFlags',
  async params => {
    const { message, error } = await syncFeatureFlags(params)

    return {
      message,
      error: error ?? null
    }
  }
)

export const sendCreateFeatureFlag = createAsyncThunk(
  'sendCreateFeatureFlag',
  async params => {
    const { featureFlag, error } = await createFeatureFlag(params)

    return {
      featureFlag,
      error
    }
  }
)

export const sendUpdateFeatureFlag = createAsyncThunk(
  'sendUpdateFeatureFlag',
  async params => {
    const { featureFlag, error } = await updateFeatureFlag(params)

    return {
      featureFlag,
      error
    }
  }
)

export const sendFetchFeatureFlags = createAsyncThunk(
  'sendFetchFeatureFlags',
  async params => {
    const { featureFlags } = await getFeatureFlags()
    return { featureFlags }
  }
)

const featureFlagsSlice = createSlice({
  name: 'featureFlagsReducer',
  initialState: {
    featureFlags: [],
    featureFlagsRequested: false,
    isFetching: false,
    isSyncing: false,
    message: null,
    upserted: null,
    loading: false
  },
  reducers: {
    cleanUpserted: (state, action) => {
      state.upserted = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(sendSyncFeatureFlags.pending, (state, action) => {
        state.isSyncing = true
      })
      .addCase(sendSyncFeatureFlags.fulfilled, (state, action) => {
        state.isSyncing = false
        state.message = action.payload.message
      })
      .addCase(sendCreateFeatureFlag.pending, (state, action) => {
        state.loading = true
      })
      .addCase(sendCreateFeatureFlag.fulfilled, (state, action) => {
        const { error, featureFlag } = action.payload
        if (!error) {
          state.upserted = featureFlag
          state.featureFlags = [...state.featureFlags, featureFlag]
        } else {
          state.error = error
        }
        state.loading = false
      })
      .addCase(sendUpdateFeatureFlag.pending, (state, action) => {
        state.loading = true
      })
      .addCase(sendUpdateFeatureFlag.fulfilled, (state, action) => {
        const { error, featureFlag } = action.payload
        if (!error) {
          state.upserted = action.payload.featureFlag

          const { featureFlags } = { ...state }
          const index = state.featureFlags.findIndex(
            ({ featureFlagId }) => featureFlagId === featureFlag?.featureFlagId
          )
          featureFlags[index] = featureFlag
        } else {
          state.error = error
        }
        state.loading = false
      })
      .addCase(sendFetchFeatureFlags.pending, (state, action) => {
        state.featureFlags = []
        state.isFetching = true
        state.featureFlagsRequested = true
      })
      .addCase(sendFetchFeatureFlags.fulfilled, (state, action) => {
        state.featureFlags = action.payload.featureFlags
        state.isFetching = false
      })
  }
})

const { actions, reducer } = featureFlagsSlice
export const { cleanUpserted } = actions

export default reducer
