import { API, graphqlOperation } from 'aws-amplify'

import { API_NAME, GRAPHQL_ROUTE } from '../config'
import { getIdToken } from '../utils'

export async function syncHelpText(params) {
  try {
    const mutation = /* GraphQL */ `
      mutation syncHelpText($fromEnv: String!, $toEnv: String!) {
        syncHelpText(fromEnv: $fromEnv, toEnv: $toEnv)
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(mutation, params)
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return {
      message: response.data.syncHelpText,
      error: null
    }
  } catch (err) {
    return { message: null, error: err.message }
  }
}

export async function syncReleaseNotes(params) {
  try {
    const mutation = /* GraphQL */ `
      mutation syncReleaseNotes($fromEnv: String!, $toEnv: String!) {
        syncReleaseNotes(fromEnv: $fromEnv, toEnv: $toEnv)
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(mutation, params)
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return {
      message: response.data.syncReleaseNotes,
      error: null
    }
  } catch (err) {
    return { message: null, error: err.message }
  }
}

export async function syncTags(params) {
  try {
    const mutation = /* GraphQL */ `
      mutation syncTags($fromEnv: String!, $toEnv: String!) {
        syncTags(fromEnv: $fromEnv, toEnv: $toEnv)
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(mutation, params)
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return {
      message: response.data.syncTags,
      error: null
    }
  } catch (err) {
    return { message: null, error: err.message }
  }
}
