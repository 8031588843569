import { API, graphqlOperation } from 'aws-amplify'

import { API_NAME, GRAPHQL_ROUTE } from '../config'
import { getIdToken } from '../utils'

export async function getZenhubReports() {
  try {
    const query = /* GraphQL */ `
      query getZenhubReports {
        getZenhubReports {
          sprintId
          sprintName
          sprintStartDate
          sprintSummaryUrl
          sprintBreakdownUrl
          createdAt
          updatedAt
        }
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(query, {})
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return {
      reports: response.data.getZenhubReports,
      error: null
    }
  } catch (err) {
    return { reports: null, error: err.message }
  }
}

export async function getZenhubReport(args) {
  try {
    const query = /* GraphQL */ `
      query getZenhubReport($sprintId: String!) {
        getZenhubReport(sprintId: $sprintId) {
          data
          sprintSummaryUrl
          sprintBreakdownUrl
        }
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(query, args)
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return {
      report: response.data.getZenhubReport,
      error: null
    }
  } catch (err) {
    return { report: null, error: err.message }
  }
}

export async function generateZenhubReport(params) {
  try {
    const query = /* GraphQL */ `
      query generateZenhubReport(
        $sprintId: String!
        $sprintName: String!
        $sprintStartDate: String!
        $toEmail: String!
      ) {
        generateZenhubReport(
          sprintId: $sprintId
          sprintName: $sprintName
          sprintStartDate: $sprintStartDate
          toEmail: $toEmail
        )
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(query, params)
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return {
      report: response.data.generateZenhubReport,
      error: null
    }
  } catch (err) {
    return { report: null, error: err.message }
  }
}

export async function getZenhubSprints() {
  try {
    const query = /* GraphQL */ `
      query getZenhubSprints {
        getZenhubSprints {
          id
          name
          startAt
          endAt
        }
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(query, {})
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return {
      sprints: response.data.getZenhubSprints,
      error: null
    }
  } catch (err) {
    return { sprints: null, error: err.message }
  }
}
