import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getMissingTranslations } from '../../api/translations'
import { setErrors } from '../../actions/util'

export const sendRequestMissingTranslations = createAsyncThunk(
  'sendRequestMissingTranslations',
  async (params, { dispatch }) => {
    const { translations, error } = await getMissingTranslations()
    if (error) dispatch(setErrors(error))
    return {
      translations,
      error
    }
  }
)

const translationsSlice = createSlice({
  name: 'translationsReducer',
  initialState: {
    error: null,
    translations: {},
    translationsRequested: false,
    isLoading: false
  },
  extraReducers: builder => {
    builder
      .addCase(sendRequestMissingTranslations.pending, (state, action) => {
        state.isLoading = true
        state.error = null
        state.translationsRequested = true
      })
      .addCase(sendRequestMissingTranslations.fulfilled, (state, action) => {
        const { error } = action?.payload

        if (!error) {
          let translations = { ...state.translations }
          action?.payload?.translations.forEach(({ file, service, url }) => {
            if (!translations.hasOwnProperty(service)) {
              translations[service] = []
            }

            translations[service].push({ file, url })
          })
          state.translations = translations
        }

        state.isLoading = false
        state.error = error
      })
  }
})

export default translationsSlice.reducer
